@import "../styles/variables.scss";

.grecaptcha-badge {
  z-index: 999999999;
  //bottom: 14rem !important;
  bottom: 5rem !important;
  @media screen and (min-width: $mobWide) {
    bottom: 7.5rem !important;
  }
}

#onetrust-consent-sdk {
  position: fixed;
  z-index: 9999999999;
  #onetrust-banner-sdk {
    display: flex;
    justify-content: center;
    z-index: 2147483646;
  }

  .ot-cookie-policy-link {
    color: $color-primary !important;
  }
}

*,
::before,
::after {
    box-sizing: border-box;
}


html {
  height: 100%;
  color: $color-white;
  scroll-behavior: smooth;
  overflow: overlay;
}
body 
{
  height: 100%;
}

button,
textarea,
input,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.switch-wrapper {
  position: relative;
  width: 100%;
}

.switch-wrapper > div {
  width: 100%;

  .routing & {
    position: absolute;
  }
}

main {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: calc(100vh - 158px);

  @media screen and (min-width: $mobWide) {
    min-height: calc(100vh - 76px);
  }
}


.layout-general {
  margin-top: 8rem;
  
  &.thankyou--keyvis {
    margin-top: 7rem;
  }

  &--conffeti {
    .layout-container {
      position: relative;
      z-index: 1;
    }
  }

  &__confetti {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    animation: confetti 1.5s .05s forwards;
    opacity: 0;
    transform: scale(.5);
  }

  &--logo-pattern {
    background: url(../assets/images/campaign/logo-patten.png) repeat;
    background-size: cover;
    min-height: 100vh;

    @media screen and (min-width: $deskWide) {
      background-size: auto;
    }
  }

  &--mt0 {
    margin-top: 0;
  }

  &--full {
    overflow-x: hidden;
    margin-top: 8.5rem;
    min-height: calc(100vh - 243px);
  
    @media screen and (min-width: $mobWide) {
      min-height: calc(100vh - 167px);
    }

    @media screen and (min-width: $tabDesk) {
      margin-top: 13.7rem;
      min-height: calc(100vh - 210px);
    }
    
  }

  &--full-texture {
    overflow-x: hidden;
    margin-top: 0;
    min-height: calc(100vh - 158px);
    background-image: url(../assets/images/campaign/template-texture-mobile--roi.jpg);
    background-repeat: no-repeat;
    background-size: cover;

    .layout-container {
      padding-top: 13.2rem;
    }

    &--gb {
      background-image: url(../assets/images/campaign/template-texture-mobile.jpg);
    }

    @media screen and (min-width: $mobWide) {
      min-height: calc(100vh - 76px);
    }

    @media screen and (min-width: $tab) {
      background-image: url(../assets/images/campaign/template-texture--roi.jpg);

      &--gb {
        background-image: url(../assets/images/campaign/template-texture.jpg);
      }
    }

    @media screen and (min-width: $tabDesk) {
      .layout-container {
        padding-top: 18.2rem;
      }
    }

    @media screen and (min-width: 1350px) {
      .layout-container {
        padding-top: 17.7rem;
      }
    }
  }

  @media screen and (min-width: $tab) {
    margin-top: 9rem;

    &--mt0 {
      margin-top: 0;
    }
  }
  @media screen and (min-width: $tabDesk) {
    margin-top: 12rem;

    &--mt0 {
      margin-top: 0;
    }
  }

  @media screen and (min-width: $deskWide) {

    &.thankyou--keyvis {
      margin-top: 8rem;
    }
    
    &--mt0 {
      margin-top: 0;
    }
  }

}

.layout-wrapper {
  display: flex;
  justify-content: center;
  $ellipseHeightMob: 8.2rem;
  $ellipseHeightDesk: 13rem;

  &--bottom-ellipse {
    position: relative;
    overflow: hidden;

    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 140%;
      left: 50%;
      bottom: calc(-#{$ellipseHeightMob} / 2);
      transform: translateX(-50%);
      border-top-left-radius: 50%;
      border-top-right-radius: 50%;
      z-index: 2;
    }

    &::before {
      height: calc(#{$ellipseHeightMob} + 3px);
      background-color: $color-accent;
    }
    &::after {
      height: $ellipseHeightMob;
      background-color: $color-primary;
    }

    .layout-container {
      padding-bottom: 10rem;
    }

    + .layout-wrapper {
      .layout-container:not(.layout-container--bottom-space) {
        padding-top: 2rem;
      }
    }

    &--secondary {
      &::after { 
        background-color: $color-secondary;
      }
    }

    @media screen and (min-width: $mobWide) {
      .layout-container {
        padding-bottom: 10rem;
      }
    }

    @media screen and (min-width: $deskWide) {
      &::before,
      &::after {
        content: "";
        width: 130%;
        bottom: calc((-#{$ellipseHeightDesk} / 2) );
        border-top-left-radius: 50% 60%;
        border-top-right-radius: 50% 60%;
      }
  
      &::before {
        height: calc(#{$ellipseHeightDesk} + 3px);
      }
      &::after {
        height: $ellipseHeightDesk;
      }

      .layout-container {
        padding-bottom: 14rem;
      }
  
      + .layout-wrapper {
        .layout-container:not(.layout-container--bottom-space) {
          padding-top: 2rem;
        }
      }
    }

    @media screen and (min-width: 1130px) {
      .layout-container {
        padding-bottom: 16rem;
      }
    }

    @media screen and (min-width: $deskWide) {
      &::before,
      &::after {
        content: "";
        width: 115%;
        border-top-left-radius: 50% 80%;
        border-top-right-radius: 50% 80%;
      }

      &::before {
        height: calc(#{$ellipseHeightDesk} + 4px);
      }
    }
  }
}

.layout-container {
  width: 100%;
  padding: 5rem 2.5rem;
  max-width: $mob;
  box-sizing: border-box;

  &--banner {
    padding: 2rem 2.5rem 4rem;
  }

  &--center {
    text-align: center;
  }

  &--bottom-space {
    padding-top: 0;

    .form__privacy {
      margin-top: 0;
    }
  }

  &--pb0 {
    padding-bottom: 0;
  }

  &--entry {
    padding-bottom: 6rem;
  }

  &--confetti {
    background-color: $color-secondary;
    background: linear-gradient(0deg, rgba(75,9,131,1) 81%, rgba(75,9,131,0) 100%);
    padding: 6rem 2.5rem 5rem 2.5rem;
  }

  &--unrestricted {
    max-width: none;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .layout-general--full & {
    padding: 3rem 2.5rem 6rem;
  }

  @media screen and (min-width: $tab) {
    padding: 5rem 2.5rem;
    max-width: 108rem;

    &--banner {
      padding: 3rem 2.5rem 5rem;
    }

    &--pb0 {
      padding-bottom: .5rem;
    }

    &--slim {
      max-width: 62.4rem;
    }

    &--bottom-space {
      padding-top: 0;
    }

    &--confetti {
      background-color: $color-secondary;
      padding: 7rem 2.5rem 7rem 2.5rem;
    }

    &--medium {
      max-width: 88rem;
    }

    &--wide {
      max-width: $deskWide;
    }

    &--unrestricted {
      max-width: none;
      padding-left: 3rem;
      padding-right: 3rem;
    }

    .layout-general--full & {
      padding: 4rem 2.5rem 8rem;
    }
  }


  @media screen and (min-width: 1130px) {
    padding: 6.5rem 0rem 10rem;

    &--bottom-space {
      padding-top: 0;
    }

    &--pb0 {
      padding-bottom: 0;
    }

    &--entry {
      padding-bottom: 6rem;
    }

    &--unrestricted {
      padding-left: 3rem;
      padding-right: 3rem;
    }

    &--wide {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }
    
  }

  @media screen and (min-width: 1350px) {
    .layout-general--full & {
      padding: 5rem 0rem 8rem;
    }

    &--wide {
      padding-left: 0;
      padding-right: 0;
    }
    
  }

}

.overflowx-hidden {
  overflow-x: hidden;
}

.mt-20 {
  margin-top: 2rem;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}