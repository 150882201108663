@import '../../styles/variables.scss';

.footer {
  $this: &;
  color: #fff;
  display: block;
  position: relative;
  z-index: 100;

  &__terms {
    display: none;
    position: absolute;
    top: 1.5rem;
    left: .5rem;
    color: #000;

    a {
      text-decoration: underline;
    }
  }

  #{&} &__logo {
    display: none;
    margin: 0;
    position: absolute;
    bottom: 1.35rem;
    left: 50%;
    transform: translate3d(-50%, 0, 0);

    > svg {
      height: 4rem;
      width: 16rem;
      fill: $color-secondary;
    }
  }

  #{&} &__menu {
    margin: -1px 0 0;
    background: #fff;
    display: flex;
    padding: 0 1.5rem;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    &__links {
      order: 2;
      width: 100%;
      flex:1;
      margin: 0 0 1.5rem;
      
      a {
        display: inline-block;
        color: $color-secondary;
        will-change: color;
        transition: 200ms ease color;
        font-size: 1.3rem;
        line-height: 1.7rem;
        font-family: $font-family-bold;
        font-weight: $font-family-medium-weight;
        border: 0;
        outline: 0.2rem solid transparent;
        
        &:focus {
          outline: 0.2rem solid $color-secondary;
          color: $color-secondary;
        }
        
        + a {
          margin-left: 1rem;
        }
      }

      .button {
        margin-left: 2rem;
        font-size: 1.4rem!important;
        line-height: 1.4rem!important;
        display: inline-block;
        color: #3B0073;
        will-change: color;
        transition: 200ms ease color;
        font-size: 1.3rem;
        line-height: 1.7rem;
        text-decoration: none;
        font-family: "canada-type-gibson", sans-serif;
        font-weight: 500;
        border: 0;
        outline: 0.2rem solid transparent;

        &:hover {
          text-decoration: underline;
          text-decoration-thickness: 0.2rem;
        }
        
        &:focus {
          box-shadow: 0 0 0px 2px $color-primary;
        }
      }
     

    }

    &__social-links {
      $icon-size: 2rem;
      font-size: 1rem;
      margin: 1rem 0;
      display: flex;
      order: 1;

      @media screen and (min-width: $mobWide) {
        order: 2;
        margin: 0 0 0 auto;
        padding: 0 1.5rem;

        &--reduced {
          padding-right: 3rem;
        }

      }

      a {
        height: auto;
        padding: 0.5rem 0.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0;
        border: 0.2rem solid transparent;

        svg {
          transition: all 200ms ease;
          display: block;
          width: $icon-size;
          height: $icon-size;
        }

        &:hover svg {
          fill: $color-secondary;
          width: 2.1rem;
          height: 2.1rem;
        }

        &:focus {
          outline: none;
          border-color: $color-secondary;
        }
      }

    }
  }

  #{&} &__copyright {
    font-size: 1.2rem;
    line-height: 1.2rem;
    margin-top: -1px;
    display: flex;
    flex-direction: column;
    padding: 0 1.5rem 2rem;
    text-align: center;
    align-items: center;
    justify-content: center;
    background: #fff;
    color: $color-secondary;

    &__text {
      margin-top: 1.1rem;
      order: 2;
      flex: 1;
      font-size: 1.2rem;
      line-height: 1.2rem;
      font-weight: $font-family-book-weight;
    }

    &__logo {
      margin-top: 0;
      order: 1;
      float: right; //IE 10 fix
      outline: 0.2rem solid transparent;

      > svg {
        $width: 11.5rem;
        $height: 2.3rem;

        width: $width;
        height: $height;
        display: block;
        fill: $color-secondary;
      }

      &:hover {
        > svg {
          width: 11.5rem;
          height: 2.4rem;
        }
      }

      &:focus,
      &:focus-visible {
        outline: 0.2rem solid $color-secondary;
      }
    }
  }

  a {
    font-size: 0.9em;
    color: currentColor;
    text-decoration: none;
  }

  svg {
    display: inline-block;
    vertical-align: middle;
    fill: $color-secondary;
  }

  .modal .modal--extra__link {
    color: $color-white;
    word-wrap: break-word;
    font-size: 1.8rem;
    line-height: 2.2rem;

    &:hover {
      color: $color-accent;
    }

    &:focus {
      outline: 0.2rem solid $color-white;
      color: $color-accent;
    }
  }

  @media screen and (min-width: $mobWide) {
    $this: &;
    &__terms {
      left: 2rem;
    }

    #{&} &__logo {
      display: inline-block;
    }

    #{&} &__menu {
      text-align: left;
      order: 1;
      flex-direction: row;
      justify-content: flex-start;
      padding: 0 5rem;

      &__links {
        margin: 1.5rem 0 0.5rem;

        a {
          &:hover {
            color: $color-secondary;
            text-decoration-line: underline;
            text-decoration-thickness: 0.2rem;
          }

          font-size: 1.4rem;
          line-height: 1.4rem;

          + a {
            margin-left: 2rem;
          }
        }


      }
    }

    #{&} &__copyright {
      align-items: flex-start;
      padding: 0 5rem 1rem;
      flex-direction: row;
      text-align: left;

      &__text {
        order: 1;
        margin-top: 0;
      }

      &__logo {
        order: 2;
        margin: 0 0 0 auto;
      }
    }
  }

}
  