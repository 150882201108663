@import '../../styles/variables.scss';

.thankyou {
  $this: &;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $color-accent;

  &__keyvis {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    background-image: url(../../assets/images/campaign/template-texture.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 37.1rem;
  }

  &__container{
    margin: 0 auto;
  }

  .layout-container--slim {
    max-width: 67rem;
  }

  &__cta {
    margin-top: 2.2rem;
  }

  &__para {
    font-size: 1.6rem;
    line-height: 2.4rem;
    letter-spacing: 0rem;
    margin: 0 auto;
    position: relative;

    p a {
      font-weight: $font-family-base-weight;
      word-break: break-word;
    }
  }

  &__img {
    display: block;
    width: auto;
    height: auto;
    min-width: 100%;
    max-width: 100%;
    object-fit: contain;
    margin: 6rem 0 8rem;
  }

  @media screen and (min-width: 420px) {
    &__keyvis {
      height: 44.4rem;
    }
  }

  @media screen and (min-width: 640px) {
    &__keyvis {
      height: 55.4rem;
    }
  }


  @media screen and (min-width: 720px) {
    &.layout-general--full .layout-container--slim {
      padding: 2.5rem 0 5rem;
    }
  }


  @media screen and (min-width: $tab) {
    &__cta {
      margin-top: 2.7rem;
    }

    &__para {
      font-size: 1.6rem;
      line-height: 2.4rem;
      max-width: 54rem;
    }

  }
}
