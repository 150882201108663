@import '../../styles/variables.scss';

.faqs {
  margin-top: 2rem;

  .accordion + .accordion {
    margin-top: 1.5rem;
  }

  &__section {
    margin-top: 3rem;

    .type-h3 {
      font-size: 1.8rem;
      line-height: 2.1rem;
      letter-spacing: 0.054rem;
      margin-bottom: 1.5rem;
    }
  }

  &__covid {
    padding-top: 2rem;
    font-size: 1.1rem;
    line-height: 1.5rem;
    text-align: left;
    display: inline-block;
  }

  @media screen and (min-width: $tab) {
    &__section {
      margin-top: 3.6rem;
      
      &:first-child {
        margin-top: 0;
      }
    }

    .accordion + .accordion {
      margin-top: 1.2rem;
    }

    .type-h3 {
      font-size: 2.4rem;
      line-height: 2.4rem;
      letter-spacing: 0.072rem;
      margin-bottom: 2.7rem;
    }


  }
}