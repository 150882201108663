@import '../../styles/variables.scss';

.visually-hidden {
  display: none;
}

.relative {
  position: relative;
}

.home {
  $this: &;

  &__banner {
    background-color: transparent;
    flex-direction: column;
    overflow: hidden;

    &-container {
      height: 100%;
    }
  }

  &__entrybar {
    text-align: center;
    z-index: 2;

    &-confetti {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      animation: confetti 1.5s .05s forwards;
      opacity: 0;
      transform: scale(.5);
      z-index: 0;
    }


    @media screen and (min-width: $tab) {


      + .home__prize-block-list .layout-container {
        padding-top: 0;
      }
    }
  }

  &__intro {
    position: relative;
    z-index: 1;
    display: block;
    margin: 0 auto;
    max-width: 62rem;

    h1 {
      visibility: hidden;
      position: absolute;
      height: 0;
    }

    .type-h2 {
      margin: 0 2rem 2.2rem;
      letter-spacing: 0;

      @media screen and (min-width: $tab) {
        margin-bottom: 2.7rem;
        padding: 0;
      }
    }

    .type-h3 {
      margin-bottom: 2.1rem;
      padding: 0 3rem;
    }

    .type-h1 {
      margin-bottom: 1.7rem;
    }

    &--slim {
      max-width: 48.4rem;

      .type-h3 {
        padding: 0;
        letter-spacing: 0;
      }

      .home__intro-body {
        padding: 0;
      }
    }

    &--wide {
      max-width: 71.2rem;

      .home__intro-body p b {
        display: inline-block;
      }
    }

    @media screen and (min-width: $tab) {

      .type-h3 {
        margin-bottom: 2rem;
      }

      #{$this}__intro-body {

        p {
          letter-spacing: 0;
        }
      }

      &--slim {
        padding: 0 4rem;
  
        .type-h3 {
          margin-bottom: 2rem;
        }

        #{$this}__intro-body {

          &--slim {
            max-width: 29.8rem;
            margin: 0 auto;
          }

        }
      }

      &--padding {
        padding-bottom: 2rem;
      }
    }

    @media screen and (min-width: $tabDesk) {

      &--slim {
        padding: 0;

        p {
          padding: 0;
        }

      }
    }

  }

  &__intro-body {

    .link {
      font-weight: 600;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    +.home__intro-body {
      margin-top: 4rem;

      @media screen and (min-width: $tab) {
        margin-top: 5rem;
      }
    }

    @media screen and (min-width: $tab) {
      p {
        letter-spacing: 0.02rem;
      }
    }

  }

  &__cta {
    position: relative;
    z-index: 1;
    width: 100%;
    text-align: center;
    margin-top: 2.7rem;

    +.home__intro-body {
      margin-top: 4.5rem;

      @media screen and (min-width: $tab) {
        margin-top: 5.5rem;
      }
    }
  }

  &__disclaimer {
    position: relative;
    z-index: 1;
    display: block;
    max-width: 60rem;
    margin: 0 auto;
    box-sizing: border-box;
    margin-top: 3.8rem;

    @media screen and (min-width: $tab) {
      margin-top: 4rem;
    }

    p {
      font-size: 1.4rem;
      line-height: 2rem;
      font-weight: $font-family-base-weight;

      a {
        font-weight: $font-family-medium-weight;

        &:hover {
          cursor: pointer;
          text-decoration: none;
        }
      }
    }
  }

  &__carousel {
    padding-bottom: 7rem;

    @media screen and (min-width: $tab) {
     padding-bottom: 0;
    }
  }

  &__prizes {
    overflow: hidden;

    &__intro-container {
      position: relative;
    }

    &__intro {
      max-width: 46.1rem;
      margin: 0 auto;

      .type-h1 {
        padding: 0;
        margin-bottom: 2rem;
      }
      
      p {
        margin-bottom: 3.6rem;
      }
    }

    &__nav {
      position: absolute;
      width: 100%;
      right: 0;
      bottom: -52rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 2rem;

      .swiper-button-next,
      .swiper-button-prev {
        position: relative;
        left: auto;
        right: auto;
        bottom: auto;
        top: auto;
        margin: 0;
      }

      .swiper-pagination {
        width: auto;
        position: relative;
        transform: unset;
        bottom: auto;
        left: auto;
        margin: 0 2rem;
        font-weight: $font-family-semibold-weight;
      }

      @media screen and (min-width: $tab) {
        bottom: 0;
        margin-bottom: 0;
        width: auto;
      }
    }

    &__footer {
      .type-h3 {
        max-width: 45rem;
        letter-spacing: 0;
        margin: 0 auto;
  
        @media screen and (min-width: $tabDesk) {
          max-width: 52.8rem;
        }
      }
  
      .button--text-link {
        font-size: 2rem;
        line-height: 2rem;
        text-transform: uppercase;
        font-weight: 600;
        color: $color-accent;
        margin-left: .5rem;
  
        &:hover {
          color: $color-white;
        }
      }
    }

    @media screen and (min-width: $tabDesk) {

      &__intro {
        .type-h1 {
          margin-bottom: 2.6rem;
        }
  
        p {
          margin-bottom: 5.7rem;
        }
      }

      &__footer {
        .button--text-link {
          font-size: 2.4rem;
          line-height: 2.4rem;
          margin-left: .75rem;
        }
      }
    }
  }

  &__prize-list {
    position: relative;

    .carousel__item {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .swiper-container {
      margin-bottom: 4rem;
      padding: 0;
      @media screen and (min-width: $tab) {
        overflow: visible;
        margin-bottom: 5rem;
      }
    }
  }

  &__prize-block-section {
    position: relative;
    z-index: 1;

    .type-h2 {
      max-width: 63rem;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 3rem;
    }

    @media screen and (min-width: $tabDesk) {
      .type-h2 {
        margin-bottom: 5rem;
      }
    }
    
  }

  &__howitworks {
    .type-h1--xl {
      margin-bottom: 0;
    }

    &__intro{
      padding: 0 3rem;
      margin-top: 1.9rem;
    }

    .home__cta {
      margin-top: 3rem;
    }

    @media screen and (min-width: $tab) {
      &__intro{
        padding: 0;
        margin-top: 2.8rem;
      }

      .home__cta {
        margin-top: 4.4rem;
      }

    }
  }

  &__prize-block-section {
    .layout-container {
      padding-top: 0;
    }
  }

  &__prize-blocks {
    overflow: hidden;
    border-radius: 1.5rem;
    position: relative;
    display: flex;
    flex-direction: column;

    &__disclaimer {
      font-size: 1.6rem;
      line-height: 1.8rem;
      padding: 0 4rem;
      margin-bottom: 2rem;
      color: $color-accent;
    }

    + .type-h1 {
      margin-top: 3.7rem;
    }



    @media screen and (min-width: $tab) {
      + .type-h1 {
        margin-top: 7rem;
      }

      
      &-cont {

        
        .layout-container--medium {
          padding: 0rem 2.0rem 8.0rem;

          .type-h1 {
            margin-bottom: 3rem;
          }
        }
      }

      &-cols {
        display: flex;
        margin-top: 3rem;

        .prize-block {
          width: 50%;
        }
      }

      &--grid {
        column-gap: 0;
      }
    }

    @media screen and (min-width: $tab) {
      &--grid {
        column-gap: 6rem;
      }
    }

  }

  &__steps {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    margin: 4.5rem auto 2.3rem;
    align-items: center;

    .step {
      max-width: 23.8rem;
    }

    @media screen and (min-width: $tab) {
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
      margin: 5rem auto 5.6rem;
      flex-wrap: wrap;
      max-width: 80.1rem;

      .step {
        width: calc(100% / 4 - 5px);

        +.step {
          margin-left: 4.3rem;
        }

        &:nth-of-type(3n+1) {
          margin-left: 0;
        }
        
        &:nth-of-type(4),  &:nth-of-type(5) {
          margin-top: 5rem;
        }
      }

      &--wide {
        max-width: unset;
        flex-wrap: nowrap;

        .step {
          &:nth-of-type(4), &:nth-of-type(5) {
            margin-top: unset;
            margin-left: 4.3rem;
          }
        }
      }
    }

    @media screen and (min-width: $tabDesk) {
      .step {
        width: calc(100% / 3 - 5px);
      }
    }
  }

  &__player-badges {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin: 3.8rem auto;

    .player-badge {
      margin-bottom: 1.5rem;
    }

    +.home__intro-body {

      .type-h3 {
        margin-bottom: 2.1rem;
      }

    }

    @media screen and (min-width: $tabDesk) {
      justify-content: center;
      max-width: unset;
      margin: 5.8rem auto;

      .player-badge {
        max-width: unset;
        margin-bottom: 0;

        + .player-badge {
          margin-left: 1.7rem;
        }
      }

      +.home__intro-body {
        
        .type-h3 {
          margin-bottom: 2.1rem;
        }

        p {
          padding: 0 11rem;
        }
      }
    }

  }
}

.small-astrix {
  font-size: 0.6em;

}