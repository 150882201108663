@import '../../styles/variables.scss';

.modal-background {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  justify-content: center;
  align-items: center;
  z-index: 999999999;
  overflow-y: auto;
  background: transparent;
  opacity: 0;
  transition: opacity .3s;

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: $color-black;
    opacity: 0.75;
  }

  &--show {
    opacity: 1;
  }

  .modal--loader,
  .modal--panel {
    grid-column: span 10 / span 10;
    grid-column-start: 2;
  }

}