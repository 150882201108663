@import '../../styles/variables.scss';

.textfield {
  $this: &;
  color: $color-secondary;

  &__label {
    display: block;
    text-align: left;
    line-height: 2.1rem;
    margin-bottom: 0.5rem;
    color: $color-white;
  }

  &__label-info {
    display: block;
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: $color-white;
  }

  &__info {
    text-align: left;
    color: $color-white;
  }

  &__input-wrapper {
    position: relative;
  }

  &__focus {
    background: transparent;
    display: block;
    position: absolute;
    top: -0.5rem;
    left: -0.5rem;
    width: calc(100% + 10px);
    height: calc(100% + 10px);
    border-radius: 12px;
    border: 2px solid transparent;
  }

  &__input {
    width: 100%;
    color: $color-secondary;
    font-size: 1.6rem;
    line-height: 2.1rem;
    font-weight: $font-family-medium-weight;
    border: $textfield-border;
    border-radius: $textfield-border-radius;
    padding: $textfield-padding;
    position: relative;
    z-index: 1;
    -webkit-user-select: text; 
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;    

    &:-webkit-autofill {
      -webkit-text-fill-color: $color-secondary;
      -webkit-box-shadow: 0 0 0px 1000px $color-white inset;
    }

    &--select {
      width: 100%;
      background-color: $color-white;
      color: $color-secondary;
      -webkit-appearance: none;
      -moz-appearance: none;
      background-image: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="%233B0073" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"/></svg>');
      background-repeat: no-repeat;
      background-position-x: calc(100% - 0.5rem);
      background-position-y: 50%;
      border-radius:  $textfield-border-radius;
      padding: $textfield-padding;
      padding-right: 5rem;

      &:focus {
        outline: none;
      }
    }

    #{$this}--error & {
      border: $textfield-border-error;
    }

    &:focus {
      outline: none;

      + #{$this}__focus {
        border-color: $color-accent;
      }
    }

    &[disabled] {
      background: rgba($color-secondary, .5);
      color: $color-white;
    }

    &::-moz-placeholder,
    &::-webkit-input-placeholder,
    &:-ms-input-placeholder,
    &::placeholder {
      opacity: 1;
      color: $color-grey;
    }

  }

  &--tooltip {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.8rem;

    #{$this}__label {
      margin-bottom: 0;
    }

  }

  &--phone {
    #{$this}__inputs {
      display: flex;
      z-index: 1;
      position: relative;

      &__codes {
        position: relative;
        width: 11.5rem;

        .custom-dropdown {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

        .textfield__input--select {
          color: $color-grey;
          font-weight: $font-family-book-weight;
          padding-left: 4rem;
          padding-right: 3rem;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

        &--uk {
          &::before {
            content: "";
            display: block;
            width: 3rem;
            height: 1.5rem;
            background-image: url(../../assets/images/flag-UK.svg);
            background-size: contain;
            background-repeat: no-repeat;
            position: absolute;
            top: 50%;
            left: 1rem;
            transform: translateY(-50%);
            z-index: 2;
          }
        }
    
        &--roi {
            &::before {
              content: "";
              display: block;
              width: 3rem;
              height: 1.5rem;
              background-image: url(../../assets/images/flag-IRE.svg);
              background-size: contain;
              background-repeat: no-repeat;
              position: absolute;
              top: 50%;
              left: 1rem;
              transform: translateY(-50%);
              z-index: 2;
              
            }
        }
      }

      .textfield__input:not(.textfield__input--select) {
        padding-left: 0;
        width: calc(100% - 115px);
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    .textfield__input-wrapper {
      &:focus-within {
        .textfield__focus {
          border-color: $color-accent;
        }
      }
    }

    &.textfield--error {
      .textfield__input:not(.textfield__input--select) {
        border-left: 0;
      }
      .textfield__input--select {
        border-right: 0;
      }

      .custom-dropdown {
        border: $textfield-border-error;
        border-right: 0;
      }
    }
  }

  &--custom-dd {
    .textfield__input-wrapper {
      .custom-dropdown--multiple {
        z-index: 1;
      }

      &:focus-within {
        .textfield__focus {
          border-color: $color-accent;
        }
      }
    }
  }

  &--uppercase {
    #{$this}__input {
      text-transform: uppercase;
    }
  }
  
}