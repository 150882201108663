@import "styles/variables.scss";

.otp-validation {
  .form__intro {
    max-width: 44rem;
    margin: 0 auto 3rem
  }

  .textfield__label {
    margin: 0.5rem 0;
  }

  &__icon {
    margin-bottom: 2rem;
  }
  
  &__ctas {
    margin-top: 3rem;

    .link, .button, a, button {
      display: block;
      margin: 0 auto 2rem;
    }
  }

  &__resend {
    margin-top: 1em;
    .button--text-link {
      font-weight: $font-family-base-weight;
    }
  }

  .type-h1 {
    word-break: keep-all;
  }

  .type-h3 {
    max-width: 44rem;
    margin: 0 auto;
  }

  @media screen and (min-width: $tabDesk) {
    .form__intro {
      margin-top: 2rem;
    }
  }
}