html {
  font-size: 62.5%;
  font-family: $font-family-base;
  font-weight: $font-family-book-weight;
}

body {
  font-size: 1.6rem;
  line-height: 2.4rem;
}

sub, sup {
  font-size: 65%;
}

.text-center {
  text-align: center;
}

.type-h1, 
.type-h2,
.type-h3 {
  font-family: $font-family-base;
  color: $color-accent;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 1.5rem;

  &--secondary {
    color: $color-secondary;
  }

  &--white {
    color: $color-white;
  }

  @media screen and (min-width: $tabDesk) {
    margin-bottom: 1.2rem;
  }
}

.type-h1 {
  font-weight: $font-family-semibold-weight;
  font-size: 3rem;
  line-height: 3rem;
  letter-spacing: 0.03rem;

  &--xl {
    font-size: 4rem;
    line-height: 3.6rem;
    letter-spacing: 0.03rem;
    margin-bottom: 3rem;
  }

  @media screen and (min-width: $tabDesk) {
    font-size: 5.2rem;
    line-height: 4.8rem;
    letter-spacing: 0.03rem;

    &--xl {
      font-size: 5.2rem;
      line-height: 4.8rem;
      letter-spacing: 0.03rem;
      margin: 2rem 0 0;
    }
  }
}

.type-h2 {
  font-weight: $font-family-semibold-weight;
  font-size: 2.8rem;
  line-height: 2.8rem;
  letter-spacing: 0.084rem;

  @media screen and (min-width: $tabDesk) {
    font-size: 3.6rem;
    line-height: 3.2rem;
    letter-spacing: 0.108rem;
  }
}

.type-h3 {
  font-weight: $font-family-medium-weight;
  font-size: 2rem;
  line-height: 2rem;
  letter-spacing: 0.03rem;

  @media screen and (min-width: $tabDesk) {
    font-size: 2.4rem;
    line-height: 2.4rem;
    letter-spacing: 0.03rem;
  }

}

.lowercase {
  text-transform: lowercase;
}

.italic {
  font-style: italic;
}

b, strong {
  font-weight: $font-family-medium-weight;
}

a {
  color: $color-white;

  &:hover {
    color: $color-accent;
  }

  &:focus {
    outline: none;
  }
  &:focus-visible {
    outline: 0.2rem solid $color-accent;
  }
}

p {
  margin: 0;

  a {
    font-weight: $font-family-medium-weight;
    text-decoration: underline;
  }
}

p + p {
  margin-top: 1rem;
}