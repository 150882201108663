// Colours
$color-primary: #4B0E83;
$color-secondary: #3B0073;
$color-tertiary: #7846A2;
$color-accent: #EBC373;
$color-accent-secondary :#FFECB2;
$color-alert: #FF4D4D;
$color-white: #ffffff;
$color-grey: #696969;
$color-black: #000000;

$color-lilac: #D2C1E0;

// Button
$button-font-weight: $font-family-semibold-weight;
$button-border: .1rem solid $color-accent;
$button-border-focus: .1rem solid $color-accent;
$button-border-radius: 2.4rem;
$button-background: $color-accent;
$button-background-gradient: transparent linear-gradient(90deg, #F1CD76 0%, #FFECB2 31%, #D0A651 66%, #FFECB2 100%) 0% 0% no-repeat padding-box;
$button-background-gradient-hover: $color-tertiary;
$button-padding: 1.5rem 3rem 1.3rem;

// Steps
$step-border: .3rem solid $color-white;
$step-border-radius: 50%;
$step-background: $color-white;
$step-padding: 1.2rem 12rem;
$step-width: 24rem;

// Forms
$textfield-border-width: .7rem;
$textfield-border: .3rem solid $color-white;
$textfield-border-error: .3rem solid $color-alert;
$textfield-border-focus: .3rem solid $color-accent;
$textfield-border-radius: 0.8rem;
$textfield-padding: 0.6rem 1.6rem;

// Thank you banner
$thanks-border-width: .3rem;
$thanks-border: .3rem solid $color-white;
$thanks-border-radius: 2rem;