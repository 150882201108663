@import '../../styles/variables.scss';

.club-shirt-sizes-template {
  .type-h1 + .club-shirt-block {
    margin-top: 3rem;
  }

  @media screen and (min-width: $tab) {
    .type-h1 + .club-shirt-block {
      margin-top: 4.5rem;
    }
  }
}

.club-shirt-block {
  position: relative;

  &__tables {
    display: none;
    margin-top: 2rem;
  }

  &__label {
    position: relative;
    padding-right: 3rem;
  }

  &__label__icon {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    right: 0;
    width: 2rem;
    height: 2rem;
    margin-top: -.2rem;

    span {
      position: absolute;
      border-radius: .3rem;
      display: block;
      height: .3rem;
      width: 1.6rem;
      background: $color-white;
      top: 50%;

      &:nth-of-type(1) {
        transition: transform .3s;
        transform: rotate(90deg);
      }
    }
  }

  &__input {
    position: absolute;
    width: 0.1rem;
    height: 0.1rem;
    padding: 0;
    margin: -0.1rem;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;

    &:checked {
      + label {

        .club-shirt-block__label__icon {
          span:nth-of-type(1) {
            transform: rotate(0deg);
            opacity: 0;
          }
        }

        + .club-shirt-block__tables {
          display: block;
        }
      }
    }
  }

  .type-h2 {
    display: block;
    text-align: left;
  }

  + .club-shirt-block {
    margin-top: 2.5rem;
  }

  @media screen and (min-width: $tab) { 

    &__input {
      display: none;
    }

    &__tables {
      display: block;
      margin-top: 2.5rem;
    }

    &__label {
      padding-right: 0;
    }

    &__label__icon {
      display: none;
    }
  
    + .club-shirt-block {
      margin-top: 3.5rem;
    }
  }
}

.sizes-table {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__sizes-column {
    display: flex;
    flex-direction: column;
    width: 100%;

    .sizes-table__sizetype {
      display: block;
    }
  }

  &__type-column {
    width: 100%;
    text-align: left;
    margin-bottom: 1.5rem;

    .sizes-table__sizetype {
      display: none;
    }
  }
  
  &__size-block {
    display: flex;
    width: 100%;
    text-align: left;

    > div {
      flex-grow: 1;
      font-size: 1.4rem;
      line-height: 1.6rem;
      

      &:first-child {
        width: 5rem;
        flex-grow: unset;
      }
    }

    .sizes-table__heading {
      font-size: 1.4rem;
      line-height: 1.6rem;
    }

    + .sizes-table__size-block {
      margin-top: 1rem;
    }
  }

  &__heading {
    text-transform: uppercase;
    font-family: $font-family-semibold;
    font-weight: $font-family-semibold-weight;
  }

  + .sizes-table {
    margin-top: 2rem;
  }

  @media screen and (min-width: $tab) { 
    flex-direction: row;

    &__type-column {
      display: block;
      width: 15%;
      margin-bottom: 0;
  
      > div + div {
        margin-top: 0.5rem;
      }

      .sizes-table__sizetype {
        display: block;
      }

      .sizes-table__heading {
        font-size: 1.8rem;
        line-height: 2.2rem;
      }
    }

    &__sizes-column {
      flex-direction: row;
      width: 85%;

      .sizes-table__sizetype {
        display: none;
      }

      .sizes-table__heading {
        font-size: 1.8rem;
        line-height: 2.2rem;
      }
    }

    &__size-block {
      display: block;
      width: 12.5%;
  
      > div {
        &:first-child {
          width: auto;
        }

        + div {
          margin-top: 1rem;
        }
      }

      + .sizes-table__size-block {
        margin-top: 0;
      }
    }
  }
}