@import "../../styles/variables.scss";

.otp-input {
  display: flex;
  justify-content: center;

  .textfield {
    width: calc(100% / 6 - 8px);
    max-width: 4.5rem;

    label {
      font-weight: normal;
      font-size: 1.4rem;
      line-height: 1.6rem;
      margin: 0;
    }

    &__input {
      padding: 1.1rem 0.5rem;
      text-align: center;
      text-transform: uppercase;
    }

    + .textfield {
        margin-left: 1rem;
    }
  }
}

