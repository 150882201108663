@import "styles/variables.scss";


//ipad pro only
@media screen and (min-width: 1023px) and (max-width: 1025px) and (min-height: 1365px) and (max-height: 1367px) 
{
  .prize-select-page
  {
    height: auto;
    overflow: hidden;
  }

  .switch-wrapper .actionbar--show
  {
      transform: translateY(-200px);
  }
}


.prize-select {
  margin-bottom: 10rem;
  color: $color-primary;

  .type-h2 {
    font-size: 3rem;
    line-height: 3rem;
  }

  .button--text-link {
    color: currentColor;
    &:hover {
      text-decoration: none;
    }

    &:focus {
      box-shadow: 0 0 0px 2px currentColor;

      &:hover {
        color: $color-tertiary;
      }
    }
  }

	&__list {
	  margin: 3rem 0 0;
    min-height: 44rem;

    .swiper-container {
      overflow: visible;
    }

    .swiper-container,
    .swiper-wrapper {
      min-height: inherit;
    }

    .swiper-pagination {
      width: auto;
      left: 50%;
      transform: translateX(-50%);
      bottom: unset;
      top: calc(100% + 30px);
      font-weight: $font-family-semibold-weight;
    }

    .swiper-slide {
      .prize {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        max-width: 36rem;
        margin: 0 auto;
      }
    }
  }

  &__nav {
    position: relative;
    width: 100%;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;

    .swiper-button-next,
    .swiper-button-prev {
      position: relative;
      left: auto;
      right: auto;
      bottom: auto;
      top: auto;
      margin: 0;
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="13" height="21" viewBox="0 0 13 21"><path id="Path_14186" data-name="Path 14186" d="M307.151,456.829a2.406,2.406,0,0,0-3.416,0l-6.389,6.425-6.364-6.451a2.406,2.406,0,0,0-3.416,0,2.439,2.439,0,0,0,0,3.435l8.1,8.143a2.406,2.406,0,0,0,3.416,0l8.071-8.116A2.437,2.437,0,0,0,307.151,456.829Z" transform="translate(-456.091 307.859) rotate(-90)" fill="%23EBC373"/></svg>');
      background-color: $color-primary;
      
      &:focus,
      &:focus-visible {
        outline: none;
        border-color: $color-primary;
        box-shadow: 0 0 0px 3px $color-accent, 0px 0px 0px 5px $color-primary;
      }

      &.swiper-button-disabled {
        opacity: 1;
        background-color: $color-lilac;
        color: $color-white;
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="13" height="21" viewBox="0 0 13 21"><path id="Path_14186" data-name="Path 14186" d="M307.151,456.829a2.406,2.406,0,0,0-3.416,0l-6.389,6.425-6.364-6.451a2.406,2.406,0,0,0-3.416,0,2.439,2.439,0,0,0,0,3.435l8.1,8.143a2.406,2.406,0,0,0,3.416,0l8.071-8.116A2.437,2.437,0,0,0,307.151,456.829Z" transform="translate(-456.091 307.859) rotate(-90)" fill="%23FFFFFF"/></svg>');

        &:focus,
        &:focus-visible {
          border-color: $color-lilac;
          box-shadow: 0 0 0px 3px $color-accent, 0px 0px 0px 5px $color-white;
        }
      }
    }

    .swiper-pagination {
      width: 3rem;
      position: relative;
      margin: 0 2rem;
    }
  }

  @media screen and (min-width: $tab) {
    &.layout-container--unrestricted {
      padding-right: 0;
    }

    .type-h2 {
      font-size: 3.4rem;
      line-height: 3.4rem;
    }
  }

}