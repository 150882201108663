@import "../../styles/variables.scss";

.country-select {
  $this: &;

  &--stack {
    #{$this}__container {
      display: block;
      width: 100%;
    }

    #{$this}__item {
      width: 100%;
      margin-bottom: 3rem;



      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__control {
    -webkit-tap-highlight-color: transparent;
    display: block;
    position: relative;
    box-sizing: border-box;
    cursor: pointer;

    &:hover, &:focus {

      #{$this}__label {
        @media only screen and (min-width: $deskWide)  {
          opacity: 1;
        }
      }
    }
  }

  &__native-control {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
    /**
    * Active state
    */

    &:checked ~ #{$this}__label {
      background-color: $color-secondary;
    }
  }

  &__label {
    will-change: background-color;
    transition: 200ms ease background-color;
    display: block;
    width: calc(100% - 2rem);
    color: $color-white;
    text-align: center;
    border: 0.01rem solid $color-white;
    background-color: $color-primary;
    padding: 1.2rem 1rem;
    cursor: pointer;

    font-family: $font-family-base;
    font-weight: $font-family-medium-weight;
    font-size: 1.8rem;
    line-height: 2rem;
    letter-spacing: 0.054rem;
    margin: 0;
    text-transform: uppercase;


    @media screen and (min-width: $mob) {
      font-size: 1.6rem;
      line-height: 1.8rem;
      letter-spacing: 0.08rem;
    }

    &:hover {
      background-color: $color-secondary;
    }

  }
}