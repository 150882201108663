@import '../../styles/variables.scss';

@mixin highlight-star {
  .star__outline {
    fill: $color-secondary;
  }
  .star__bg {
    fill: $color-accent;
  }
}

.nps {
  transition: all .3s;
  transform: translateY(100%);
  background: $color-white;
  color: $color-secondary;
  padding: 2rem 1.5rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  box-shadow: 0 -5px 6px rgba(0, 0, 0, 0.36);

  &--show {
    transform: translateY(0);
  }

  &__title {
    text-transform: uppercase;
    text-align: center;
    margin-top: 0;
    font-family: $font-family-base;
    font-size: 1.6rem;
    line-height: 1.8rem;
    letter-spacing: 0.06rem;
    margin-bottom: 1.1rem;
    font-weight: $font-family-semibold-weight;

    @media screen and (min-width: $tab) {
      font-size: 2rem;
    }
  }

  &__stars {
    padding: 0 0 2rem;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;

    @media screen and (min-width: 500px) {
      width: initial;
    }

    svg {
      max-width: 100%;
    }
  }

  &__star {
    cursor: pointer;
    border: none;
    background: transparent;
    margin: 0 0rem;

    span {
      position: absolute;
      opacity: 0;
      height: 0;
    }

    .star__outline, .star__bg {
      transition: fill .1s;
    }

    &:hover, &--active {
      @include highlight-star;
    }
  }

  &--star2 {
    @for $i from 1 through 2 {
      .nps__star:nth-of-type(#{$i}) {
        @include highlight-star;
      }
    }
  }

  &--star3 {
    @for $i from 1 through 3 {
      .nps__star:nth-of-type(#{$i}) {
        @include highlight-star;
      }
    }
  }

  &--star4 {
    @for $i from 1 through 4 {
      .nps__star:nth-of-type(#{$i}) {
        @include highlight-star;
      }
    }
  }

  &--star5 {
    @for $i from 1 through 5 {
      .nps__star:nth-of-type(#{$i}) {
        @include highlight-star;
      }
    }
  }

  &__key {
    position: absolute;
    bottom: 3rem;
    font-size: 1.6rem;
    font-weight: $font-family-base-weight;

    &--bad {
      left: 1.5rem;
    }

    &--good {
      right: 1.5rem;
    }

    @media screen and (min-width: 500px) {
      position: relative;
      bottom: auto;

      &--bad {
        margin-right: 1rem;
        left: initial;
      }
  
      &--good {
        margin-left: 1rem;
        right: initial;
      }
    }
  }

  &__close {
    transition: color .2s;
    color: grey;
    background: transparent;
    text-decoration: underline;
    border: none;
    cursor: pointer;
    font-weight: $font-family-medium-weight;
    font-size: 1.6rem;

    &:hover {
      color: $color-secondary;
    }
  }

  @media screen and (min-width: $tab) {
    padding: 2.2rem 2rem;
  }
}