@import './variables.scss';

body {
  background: $color-primary;

    &.overflow-hidden-mediumdown {
      @media screen and (max-width: 1200px) {
      overflow: hidden;
    }
  }

}

.bg-secondary {
  background-color: $color-secondary;
}

.text-center {
  text-align: center;
}