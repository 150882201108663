@import 'styles/variables.scss';


.keyvis {
  position: relative;
  background: transparent;
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 48rem;
  overflow: hidden;
  margin-top: 4rem;
  background-color: $color-accent;

  video {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center 75%;
    min-height: inherit;
    max-height: inherit;
  }

  &__heading {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: bottom;
  }

  &__bg {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    min-height: inherit;

    picture, img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      min-height: inherit;
    }
  }

  &__placeholder {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    min-height: inherit;
    max-height: 92rem;
    margin-top: 2rem;
    margin-bottom: 3rem;
  }

  @media screen and (min-width: $mobWide) {    
    min-height: unset;
    //max-height: 92rem;
  }

  @media screen and (min-width: $tabDesk) {   
    &__placeholder {
      margin-top: 6rem;
      margin-bottom: 3rem;
    }
  }

  @media screen and (min-width: $deskWide) {
    margin-top: 0;
    //max-height: 108rem;

    &__placeholder {
      margin-top: 8rem;
      margin-bottom: 1rem;
    }

  }

}

.react-parallax {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  max-height: inherit;
  min-height: inherit;
}