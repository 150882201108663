@import '../../styles/variables.scss';

.country-select {
  $this: &;

  &__inputs {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 3rem;

    .link--lozenge:first-child {
      margin-top: 0;
    }

    @media screen and (min-width: $tab) {
      margin-top: 2.8rem;
    }
  }

  &__codes-wrap {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  &__codes {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  &__retailer {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  &__intro {
    font-size: 1.8rem;
    line-height: 2.2rem;
    font-weight: $font-family-medium-weight;

    &:focus,
    &:focus-visible { 
      outline: none; 
    }
  }

  @media screen and (min-width: $mob) {
    &__inputs {
      max-width: 36.4rem;
      margin: 3rem auto 0rem;
    }

    &__codes {
      flex-direction: row;
    }

  }

  &--blocks {
    #{$this}__container {
      display: block;
      width: 100%;
    }

    #{$this}__item {
      width: 100%;
      margin-bottom: 3rem;

     

      &:last-child {
        margin-bottom: 0;
      }

      .button {
        width: 100%;
        margin: 0 !important;
      }
    }

  }

  @media screen and (min-width: $tab) {
    &__inputs {
      margin-top: 3.5rem;
    }
  }
}