@import '../../styles/variables.scss';

.button {
  position: relative;
  transition: all .2s;
  display: inline-block;
  width: 100%;
  color: $color-secondary;
  font-family: $font-family-bold;
  font-weight: $font-family-semibold-weight;
  font-size: 1.8rem;
  line-height: 1.8rem;
  letter-spacing: 0.03rem;
  text-transform: uppercase;
  border-radius: $button-border-radius;
  background: $button-background-gradient-hover;
  border: $button-border;
  padding: $button-padding;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  overflow: hidden;

  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: $button-background;
    background: $button-background-gradient;
    opacity: 1;
    transition: opacity .3s ease-in-out;
    border-radius: $button-border-radius;
  }

  span {
    position: relative;
  }

  &:hover {
    outline: none;
    color: $color-white;
    border-color: $button-background-gradient-hover;

    &:before {
      opacity: 0;
    }
  }

  &:focus,
  &:active {
    outline: none;
    color: $color-secondary;
    box-shadow: 0 0 0px 3px $color-primary, 0px 0px 0px 5px $color-white;

    &:before {
      opacity: 1;
    }

    &:hover {
      color: $color-white;
      &:before {
        opacity: 0;
      }
    }
  }

  &:disabled {
    cursor: no-drop;

    &:before {
      opacity: 0.5;
    }

    &:hover {
      color: $color-secondary;
      &:before {
        opacity: 0.5;
      }
    }
  }

    
  @media screen and (min-width: $mobWide) {
    max-width: 36.4rem;
    letter-spacing: 0.05rem;
  }

  &--full {
    width: 100%;

    @media screen and (min-width: $mobWide) {
      max-width: unset;
    }
  }
}

.button--text-link {
  padding: 0;
  letter-spacing: 0;
  text-decoration: none;
  text-transform: none;
  min-width: unset;
  width: auto;
  color: $color-white;
  font-size: 1.6rem;
  line-height: 2rem;
  border: 0;
  background: transparent;
  cursor: pointer;
  text-decoration: underline;
  border-radius: 0;
  font-weight: $font-family-medium-weight;

  &:before {
    display: none;
  }

  &:focus {
    outline: none;
    border: 0;
    box-shadow: none;
    background: transparent;
    color: $color-white;
    box-shadow: 0 0 0px 2px $color-accent;
    text-decoration: none;
  }

  &:focus-visible {
    outline: none !important;
  }

  &:hover {
    background: transparent;
    color: $color-accent;
    border: 0;


  }
}