@import '../../styles/variables.scss';

.prize {
  $prize: &;
  position: relative;
  background: $color-primary;
  text-align: center;
  padding: 0 1rem 2rem;

  &--selectable {
    background: transparent;
  }

  &__border {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    border: 0.4rem solid transparent;
    border-radius: 2rem;
  }

  &__radio {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    cursor: pointer;

    &:checked {

      + .prize__border {
        border-color: $color-accent-secondary;

        + .prize__image {
          transform: scale(1);
          //max-height: 36rem;
        }
      }

      + .prize__border + .prize__image + .prize__title {
        margin-top: 2.3rem;
      }
    }
    

    @media screen and (min-width: 1024px) {
      
      &:hover {
        
        + .prize__border + .prize__image {
          transform: scale(1);
         // max-height: 36rem;
        }

        + .prize__border + .prize__image + .prize__title {
          margin-top: 2.3rem;
        }
      }

    }
  }


  &__tick {
    position: relative;
    display: block;
    width: 100%;
    height: 90%;
    -ms-transform: rotate(40deg); /* IE 9 */
    -webkit-transform: rotate(40deg); /* Chrome, Safari, Opera */
    transform: rotate(40deg);
    box-sizing: border-box;
    opacity: 0;
    visibility: hidden;
    
    &::before {
      content: "";
      position: absolute;
      display: block;
      width: 0.7rem;
      height: 100%;
      background-color:#008A22;
      left: 50%;
      top: 0;
      border-radius: 0.7rem;
      box-sizing: border-box;
    }
    
    &::after {
      content: "";
      position: absolute;
      display: block;
      width: 45%;
      height: 0.7rem;
      background-color:#008A22;
      left: calc(50% - 2px);
      top: calc(100% - 7px);
      border-radius: 0.7rem;
      transform: translateX(-50%);
      box-sizing: border-box;
    }
  
  
  }

  &__fake-radio {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
  }

  &__title {
    font-family: $font-family-base;
    font-weight: $font-family-semibold-weight;
    position: relative;
    color: $color-primary;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 2rem;
    line-height: 2.4rem;
    letter-spacing: 0.06rem;
    text-align: center;
    margin: 0;
//    margin-top: 2.3rem;
    word-break: break-all;
    transition: all .3s ease-in-out;

    @media screen and (min-width: $tab) {
      font-size: 2.8rem;
      line-height: 2.4rem;
      letter-spacing: 0.084rem;
    }
  }

  &__info {
    padding: 1rem 1rem 2rem;

    p {
      font-size: 1.6rem;
    }

    ol {
      margin: 0;
      padding: 1rem 2rem;
      list-style: disc;
    }

    @media screen and (min-width: $tab) {
      padding: 1rem 2rem 2rem;
    }
  }


  &__image {
    position: relative;
    transition: all .3s ease-in-out;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    transform: scale(.85);
    //max-height: 28rem;

    svg {
      position: relative;
      display: block;
      max-width: 100%;
      transition: all .3s ease-in-out;
    }
  }

  &:focus {
    outline: none;
  }

  &--sport {
    #football_alpha {
      width: 248.688px;
      height: 254.905px;
      transform: translate(19.135px, 120.026px);
    }

    &.checked {
      #football_alpha {
        animation-name: jump-click;
        animation-timing-function: ease-in-out;
        animation-iteration-count: 1;
        animation-duration: 0.5s;
        animation-delay: .3s;
        animation-play-state: revert;
      }
    }

    @media screen and (min-width: $deskWide) {
      &:hover:not(.checked) {
        #football_alpha {
          animation-name: jump;
          animation-timing-function: ease-in-out;
          animation-iteration-count: 1;
          animation-duration: 0.5s;
          animation-delay: .3s;
        }
      }
    }
  }

  &--festivals {
    #flag_alpha {
      width: 270.857px;
      height: 433.77px;
      transform: translate(9.344px, 61.708px);
    }

    &.checked #flag_alpha {
      animation-name: festivals-click;
      animation-timing-function: ease-in-out;
      animation-iteration-count: 1;
      animation-duration: 0.5s;
      animation-delay: .3s;
    }

    @media screen and (min-width: $deskWide) {
      &:hover:not(.checked) {
        #flag_alpha {
          animation-name: festivals;
          animation-timing-function: ease-in-out;
          animation-iteration-count: 1;
          animation-duration: 0.5s;
          animation-delay: .3s;
        }
      }
    }
  }

  &--travel {
    #tickets_alpha {
      transform: matrix(0.914, 0.407, -0.407, 0.914, 155.967, 0);
    }

    &.checked #tickets_alpha {
      animation-name: tickets-click;
      animation-timing-function: ease-in-out;
      animation-iteration-count: 1;
      animation-duration: 0.5s;
      animation-delay: .3s;
    }

    @media screen and (min-width: $deskWide) {
      &:hover:not(.checked) {
        #tickets_alpha {
          animation-name: tickets;
          animation-timing-function: ease-in-out;
          animation-iteration-count: 1;
          animation-duration: 0.5s;
          animation-delay: .3s;
        }
      }
    }
  }

  &--adventure {
    &.checked {
      #rollercoster_mask {
        animation-name: rollercoaster_mask-click;
        animation-timing-function: ease-in-out;
        animation-iteration-count: 1;
        animation-duration: 0.5s;
        animation-delay: .3s;
      }
    }

    @media screen and (min-width: $deskWide) {
      &:hover:not(.checked) {
        #rollercoster_mask {
          animation-name: rollercoaster_mask;
          animation-timing-function: ease-in-out;
          animation-iteration-count: 1;
          animation-duration: 0.5s;
          animation-delay: .3s;
        }
      }
    }
  }

  &--entertainment {
    #popcorn-alpha {
      width: 312.95px;
      height: 441.506px;
      transform: translate(7.606px, -45.529px);
    }

    &.checked {
      #popcorn-alpha {
        animation-name: entertainment-click;
        animation-timing-function: ease-in-out;
        animation-iteration-count: 1;
        animation-duration: 0.5s;
        animation-delay: .3s;
      }
    }

    @media screen and (min-width: $deskWide) {
      &:hover:not(.checked) {
        #popcorn-alpha {
          animation-name: entertainment;
          animation-timing-function: ease-in-out;
          animation-iteration-count: 1;
          animation-duration: 0.5s;
          animation-delay: .3s;
        }
      }
    }
  }

}

@keyframes festivals {
  0% {
    width: 270.857px;
    height: 433.77px;
    transform: translate(9.344px, 61.708px);
  }
  40% {
    width: 300.906px;
    height: 456.416px;
    transform: translate(56.844px, 13.495px) rotate(11deg);
  }
  65% {
    width: 300.906px;
    height: 456.416px;
    transform: translate(56.844px, 13.495px) rotate(11deg);
  }
  100% {
    width: 270.857px;
    height: 433.77px;
    transform: translate(9.344px, 61.708px);
  }
}
@keyframes festivals-click {
  0% {
    width: 270.857px;
    height: 433.77px;
    transform: translate(9.344px, 61.708px);
  }
  40% {
    width: 300.906px;
    height: 456.416px;
    transform: translate(56.844px, 13.495px) rotate(11deg);
  }
  65% {
    width: 300.906px;
    height: 456.416px;
    transform: translate(56.844px, 13.495px) rotate(11deg);
  }
  100% {
    width: 270.857px;
    height: 433.77px;
    transform: translate(9.344px, 61.708px);
  }
}

@keyframes jump-click {
  0% {
    width: 248.688px;
    height: 254.905px;
    transform: translate(19.135px, 120.026px);
  }
  40% {
    width: 211.331px;
    height: 216.44px;
    transform: translate(1.199px, 102.456px) rotate(-29deg);
  }
  65% {
    width: 211.331px;
    height: 216.44px;
    transform: translate(1.199px, 102.456px) rotate(-29deg);
  }
  100% {
    width: 248.688px;
    height: 254.905px;
    transform: translate(19.135px, 120.026px);
  }
}

@keyframes jump {
  0% {
    width: 248.688px;
    height: 254.905px;
    transform: translate(19.135px, 120.026px);
  }
  40% {
    width: 211.331px;
    height: 216.44px;
    transform: translate(1.199px, 102.456px) rotate(-29deg);
  }
  65% {
    width: 211.331px;
    height: 216.44px;
    transform: translate(1.199px, 102.456px) rotate(-29deg);
  }
  100% {
    width: 248.688px;
    height: 254.905px;
    transform: translate(19.135px, 120.026px);
  }
}


@keyframes tickets {
  0% {
    transform: matrix(0.914, 0.407, -0.407, 0.914, 155.967, 0);
  }
  40% {
    transform: matrix(0.819, 0.574, -0.574, 0.819, 206.192, -14.361);
  }
  65% {
    transform: matrix(0.819, 0.574, -0.574, 0.819, 206.192, -14.361);
  }
  100% {
    transform: matrix(0.914, 0.407, -0.407, 0.914, 155.967, 0);
  }
}
@keyframes tickets-click {
  0% {
    transform: matrix(0.914, 0.407, -0.407, 0.914, 155.967, 0);
  }
  40% {
    transform: matrix(0.819, 0.574, -0.574, 0.819, 206.192, -14.361);
  }
  65% {
    transform: matrix(0.819, 0.574, -0.574, 0.819, 206.192, -14.361);
  }
  100% {
    transform: matrix(0.914, 0.407, -0.407, 0.914, 155.967, 0);
  }
}


@keyframes rollercoaster_mask {
  0% {
    //transform: translate(-8px, 7.183px);
  }
  40% {
    transform: translate(-39px) scale(1.1) rotate(-3deg);
  }
  65% {
    transform: translate(-39px) scale(1.1) rotate(-3deg);
  }
  100% {
   // transform: translate(-8px, 7.183px);
  }
}
@keyframes rollercoaster_mask-click {
  0% {
    //transform: translate(-8px, 7.183px);
  }
  40% {
    transform: translate(-39px) scale(1.1) rotate(-3deg);
  }
  65% {
    transform: translate(-39px) scale(1.1) rotate(-3deg);
  }
  100% {
   // transform: translate(-8px, 7.183px);
  }
}

@keyframes entertainment {
  0% {
    width: 312.95px;
    height: 441.506px;
    transform: translate(7.606px, -45.529px);
  }
  40% {
    width: 332.95px;
    height: 469.506px;
    transform: translate(38.943px, -87.668px) rotate(8deg);
  }
  65% {
    width: 332.95px;
    height: 469.506px;
    transform: translate(38.943px, -87.668px) rotate(8deg);
  }
  100% {
    width: 312.95px;
    height: 441.506px;
    transform: translate(7.606px, -45.529px);
  }
}
@keyframes entertainment-click {
  0% {
    width: 312.95px;
    height: 441.506px;
    transform: translate(7.606px, -45.529px);
  }
  40% {
    width: 332.95px;
    height: 469.506px;
    transform: translate(38.943px, -87.668px) rotate(8deg);
  }
  65% {
    width: 332.95px;
    height: 469.506px;
    transform: translate(38.943px, -87.668px) rotate(8deg);
  }
  100% {
    width: 312.95px;
    height: 441.506px;
    transform: translate(7.606px, -45.529px);
  }
}



