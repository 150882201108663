@import '../../styles/variables.scss';

.link {
  $this: &;
  color: $color-white;
  transition: all .2s;

  &:hover, &:focus {
    color: $color-accent;
    outline: none;
  }

  &--lozenge {
    position: relative;
    width: 100%;
    display: block;
    color: $color-secondary;
    font-family: $font-family-bold;
    font-weight: $font-family-medium-weight;
    font-size: 1.8rem;
    line-height: 1.8rem;
    letter-spacing: 0.03rem;
    text-transform: uppercase;
    border-radius: $button-border-radius;
    background: $button-background-gradient-hover;
    border: $button-border;
    padding: $button-padding;
    text-decoration: none;
    text-align: center;
    box-sizing: border-box;
    overflow: hidden;

    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: $button-background;
      background: $button-background-gradient;
      opacity: 1;
      transition: opacity .3s ease-in-out;
      border-radius: $button-border-radius;
    }

    span {
      position: relative;
    }
    
    &:hover {
      outline: none;
      color: $color-white;
      border-color: $button-background-gradient-hover;
  
      &:before {
        opacity: 0;
      }
    }

    &:focus,
    &:active {
      outline: none;
      color: $color-secondary;
      box-shadow: 0 0 0px 3px $color-primary, 0px 0px 0px 5px $color-white;

      &:before {
        opacity: 1;
      }
    }

    @media screen and (min-width: $mobWide) {
      letter-spacing: 0.05rem;
    }
  }

  @media screen and (min-width: $mobWide) {
    &--lozenge {
      margin: 0 auto;
      max-width:  36.4rem;
    }
  }
}