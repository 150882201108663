@import '../../styles/variables.scss';

.accordion {
  $this: &;
  //overflow: hidden;
  border: .3rem solid $color-white;
  background: transparent;
  width: 100%;
  border-radius: 0.8rem;

  &__toggle {
    position: relative;
    font-family: $font-family-bold;
    font-weight: $font-family-medium-weight;
    background: $color-white;
    text-align: left;
    width: 100%;
    border: none;
    padding: 2rem 5.5rem 1.8rem 2.5rem;
    color: $color-secondary;
    outline: none;
    font-size: 1.4rem;
    line-height: 1.6rem;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  &__icon {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    right: 1.1rem;
    width: 3rem;
    height: 3rem;
    margin-top: -.2rem;

    span {
      position: absolute;
      border-radius: .3rem;
      display: block;
      height: .5rem;
      width: 3rem;
      background: $color-secondary;
      top: 50%;
      transition: all .3s ease-in-out;

      &:nth-of-type(1) {
        transform: rotate(90deg);
      }
    }

    &:hover {
      cursor: pointer;
      
      span {
        background: $color-accent;
      }
    }
  }

  &__logo {
    width: auto;
    height: auto;
    max-height: 12rem;
    object-fit: contain;
    margin: 0 auto;
    margin-top: 3.5rem;
    max-width: 24.1rem;
  }

  &__body {
    text-align: left;
    display: none;
    padding: 2rem 2rem 2.6rem;
    font-size: 1.4rem;
    line-height: 1.6rem;
    font-weight: $font-family-semibold-weight;

    p {
      margin: 0;
      font-weight: $font-family-book-weight;
    }

    p ~ p {
      margin-top: 1rem; 
    }
  }

  &--open {
    border-color:  $color-accent;
    border-bottom-left-radius: 0.7rem;
    border-bottom-right-radius: 0.7rem;

    #{$this}__body {
      display: block;
      padding: 2.4rem;
    }

    #{$this}__icon {
      span:nth-of-type(1) {
        transform: rotate(0deg);
        opacity: 0;
      }
    }
  }

  &--prize {
    border: none;
    border-radius: 1.5rem;

    #{$this}__body {
      text-align: center;
      padding: 1rem 1rem 3.8rem;
      border-bottom: .3rem solid $color-accent;
      border-right: .3rem solid $color-accent;
      border-left: .3rem solid $color-accent;
      border-bottom-left-radius: 1.5rem;
      border-bottom-right-radius: 1.5rem;

      .type-h3 {
        font-size: 2.4rem;
        line-height: 2.4rem;
        letter-spacing: 0;
        padding: 4.5rem .3rem 0;
      }

      & .accordion__logo {
        + .type-h3 {
          padding-top: 1.5rem;
        }
      }

      p {
        font-size: 1.6rem;
        line-height: 2.4rem;
        letter-spacing: 0;

      }
    }
    
    #{$this}__toggle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 2rem;
      line-height: 2.4rem;
      letter-spacing: 0.03rem;
      text-align: center;
      background-color: $color-accent;
      background-image: url(../../assets/images/campaign/prize-accordion-bg--roi.png);
      background-size: cover;
      background-repeat: no-repeat;
      text-transform: uppercase;
      font-weight: $font-family-semibold-weight;
      padding: 2rem .5rem;
      border-radius: 1.5rem;
      z-index: 4;

      p {
        transform: translateX(-1.5rem) translateY(.3rem);
      }
    }

    #{$this}__image {
      img {
        display: block;
        width: 6.8rem;
        height: 6.8rem;
      }
    }

    #{$this}__icon {
      position: relative;
      transform: unset;
      top: unset;
      right: 1.5rem;
      margin-top: 0;
      border-radius: 50%;
      border: 2px solid transparent;

      span {
        width: calc(100% - 2px);
        height: .4rem;
        top: 43%;
        right: 0.1rem;
      }

      &:hover {
        span {
          background-color: $color-tertiary;
        }
      }
    }

    &.accordion--open {
      #{$this}__toggle {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
    
    &--gb {
      #{$this}__toggle {
        background-image: url(../../assets/images/campaign/prize-accordion-bg.png);
      }
    }
  }

  &:focus-within {
    border-color: $color-accent;
    outline: none;
  }

  @media screen and (min-width: $mob) {
    margin-left: 0;

    &__icon {
      right: 2rem;
    }

    button:focus {
      outline: none;
      border-color: $color-accent;
    }
    
    &__toggle {
      font-size: 1.8rem;
      line-height: 2.2rem;
      padding-right: 6rem;
    }

    &__body {
      font-size: 1.6rem;
      line-height: 2.4rem;

      p ~ p {
        margin-top: 1.5rem;
      }
    }
  }

  @media screen and (min-width: $tab) {
    &--prize {
      p {
        padding: 0 2rem;
        max-width: 60.2rem;
        margin: 0 auto;
      }
      
    }
  }


  @media screen and (min-width: $tabDesk) {
    &--prize {
      border-color: $color-accent;

      #{$this}__toggle {
        font-size: 2.8rem;
        line-height: 2.4rem;
        letter-spacing: 0.03rem;
        padding: 1rem 2.5rem 1rem 2rem;
        min-height: 15rem;
        
        p {
          font-size: 2.8rem;
          line-height: 2.4rem;
          letter-spacing: 0.03rem;
          transform: translateX(-3.5rem) translateY(0.3rem);
          width: auto;
        }
      }

      #{$this}__body {
        padding: 1.7rem 5rem 5.4rem;

        .type-h3 {
          padding-top: 5.1rem;
          margin-bottom: 2rem;
        }

        & .accordion__logo {
          + .type-h3 {
            padding-top: 2.1rem;
          }
        }
      }

      #{$this}__image {
        img {
          width: 13.3rem;
          height: 100%;
        }
      }

      #{$this}__icon {
        height: 3.9rem;
        width: 3.9rem;
        right: 3.5rem;
        span {
          height: .5rem;
        }
      }
      
    }
  }
}

