@import "../../styles/variables.scss";

.date-input {
  display: flex;

  .textfield:nth-of-type(1) {
    max-width: 5rem;
  }

  .textfield:nth-of-type(2) {
    max-width: 5.5rem;
  }

  .textfield:nth-of-type(3) {
    max-width: 7rem;
  }

  .textfield + .textfield {
    margin-left: 2rem;
  }

  .textfield {
    label {
      font-weight: $font-family-book-weight;
      font-size: 1.4rem;
      line-height: 1.6rem;
    }

    &__input {
      padding: 0.4rem 1rem;
    }
  }
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}
