@import "../../styles/variables.scss";

.checkbox {
  $this: &;
  position: relative;
  text-align: left;
  padding: 2rem 0 0;

  &:first-of-type {
    padding: 0;
  }

  &__input {
    position: absolute;
    left: 0;
    padding: 1rem;
    margin: 0;
    opacity: 0;
    z-index: 2;
    width: 3rem;
    height: 3rem;
  }

  &__label {
    display: inline-block;
    padding-left: 4.1rem;
    padding-top: 0rem;
    text-align: left;
    min-height: 3rem;
    font-size: 1.6rem;
    line-height: 2.4rem;

    a {
      font-weight: $font-family-medium-weight;
    }
  }

  &__fake {
    position: absolute;
    left: 0;
    width: 2.4rem;
    height: 2.4rem;
    display: block;
    background: $color-white;
    border: .3rem solid $color-white;
    border-radius: 0.4rem;

    &:after, &:before {
      content: '';
      transform: translate(-50%, -50%) rotate(40deg);
      position: absolute;
      top: 0.9rem;
      left: 1.2rem;
      width: 0.5rem;
      height: 2.3rem;
      background: transparent;
      border-radius: 0.2rem;
    }

    &:after {
      transform: translate(-50%, -50%) rotate(-45deg);
      width: 0.5rem;
      height: 1rem;
      left: 0.3rem;
      top: 1.3rem;
    }
  }

  &__focus {
    background: transparent;
    display: block;
    position: absolute;
    top: -0.8rem;
    left: -0.8rem;
    width: calc(100% + 16px);
    height: calc(100% + 16px);
    border: 0.2rem solid transparent;
    border-radius: 0.8rem;
  }

  &__input:focus + &__fake {
    opacity: 1;

    #{$this}__focus {
      border-color: $color-accent;
    }

    #{$this}--error & {
      border: $textfield-border-error;
    }
  }

  &__input:checked + &__fake {
    opacity: 1;

    &:after, &:before {
      background: #008A22;
    }
  }

  &--alt-color {
    #{$this}__fake {
      border-color: $color-accent;
    }

    #{$this}__input:checked + #{$this}__fake {
      border-color: $color-accent;
    }
  }

  &--error {
    #{$this}__fake {
      border-color: $color-alert;
      opacity: 1;
    }

    #{$this}__focus {
      border-color:transparent;
    }
  }

  &--simple {
    #{$this}__input {
      width: 2rem;
      height: 2rem;
    }

    #{$this}__label {
      display: block;
    }

    #{$this}__fake {
      width: 2rem;
      height: 2rem;
      border: 0.2rem solid $color-primary;

      &::before,
      &::after {
        top: 0.8rem;
        left: 1.1rem;
        width: 0.3rem;
        height: 1.5rem;
      }

      &::after {
        width: 0.3rem;
        height: 0.8rem;
        left: 0.4rem;
        top: 1rem;
      }
    }

    #{$this}__focus {
      top: -0.6rem;
      left: -0.6rem;
      width: calc(100% + 12px);
      height: calc(100% + 12px);
    }
  }
}
