@import '../../styles/variables.scss';

$brand-loading: $color-accent;
$brand-success: $color-white;
$loader-size: 9rem;
$check-height: $loader-size/2;
$check-width: $check-height/2;
$check-thickness: .5rem;
$check-color: $brand-success;

.loader {
  $this: &;
  transition: all .3s;
  margin-bottom: $loader-size/2;
  border: .3rem solid rgba(0, 0, 0, 0.2);
  border-left-color: $brand-loading;
  animation: loader-spin 1.2s infinite linear;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  width: $loader-size;
  height: $loader-size;
  margin: 0;

  &--complete {
    -webkit-animation: none;
    animation: none;
    border-color: $check-color;
    background: $color-secondary;
    transition: border 500ms ease-out;
  }

  &__checkmark {
    display: none;

    #{$this}--complete & {
      display: block;
    }
    
    &--draw:after {
      animation-duration: 800ms;
      animation-timing-function: ease;
      animation-name: checkmark;
      transform: scaleX(-1) rotate(135deg);
    }
    
    &:after {
      content: '';
      opacity: 1;
      height: $check-height;
      width: $check-width;
      transform-origin: left top;
      border-right: $check-thickness solid $check-color;
      border-top: $check-thickness solid $check-color;
      left: 2rem;
      top: 4.5rem;
      position: absolute;
    }
  }
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: $check-width;
    opacity: 1;
  }
  40% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }
  100% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }
}