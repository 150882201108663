@import '../../styles/variables.scss';

.validation {
  &-summary {
    padding: 2rem;
    background: $color-secondary;
    border-radius: $textfield-border-radius;
    border: $textfield-border-error;
    margin-bottom: 2rem;
    font-size: 1.6rem;
    line-height: 2.0rem;

    @media screen and (min-width: $tab) {
      font-size: 1.8rem;
      line-height: 2.2rem;
    }
  }

  &-message {
    color: $color-alert;
    font-family: $font-gill-nova;
    font-weight: $font-family-bold-weight;
    text-align: left;
    padding: .5rem 0;
    margin: 0;
    list-style: none;
  }
}