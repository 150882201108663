@import '../../styles/variables.scss';

.logo-banner {
  background: $color-white;
  border-top: .4rem solid $color-accent;
  border-bottom: .4rem solid $color-accent;
  padding: 1rem;
  position: relative;
  height: 10.8rem;

  .slick-track {
    transition: none !important;
  }

  .slick-slide {
    display: flex !important;
    align-items: center;
    justify-content: center;
    height: 8rem;
    opacity: 0 !important;

    img {
      display: block !important;
      margin: 0 auto;
      width: 100%;
    }

    &:not(.slick-cloned) {
      transition: opacity .5s ease-out;
    }

    &.slick-active {
      opacity: 1 !important;
      transition: opacity .5s ease-in;
    }
  }

  .slick-slide:has(img.logo-banner__item--right) {
    transform: translateX(1rem);
  }

  .slick-slide:has(img.logo-banner__item--left) {
    transform: translateX(-2.75rem);

    @media screen and (min-width: 370px) {
      transform: translateX(-4.75rem);
    }

    @media screen and (min-width: 640px) {
      transform: translateX(-8rem);
    }
  }

  .slick-slide:has(img.logo-banner__item--mid-left) {
    transform: translateX(-5rem);

    @media screen and (min-width: 400px) {
      transform: translateX(-7rem);
    }
  }

  .slick-slide:has(img.logo-banner__item--far-left) {
    transform: translateX(-7rem);

    @media screen and (min-width: 370px) {
      transform: translateX(-8rem);
    }

    @media screen and (min-width: 640px) {
      transform: translateX(-14rem);
    }
  }

  .slick-slide:has(img.logo-banner__item--sea) {
    transform: translateY(.25rem);
  }

  .slick-slide:has(img.logo-banner__item--arc),
  .slick-slide:has(img.logo-banner__item--dun) {
    transform: translateX(-.5rem);
  }

  .slick-slide:has(img.logo-banner__item--ved),
  .slick-slide:has(img.logo-banner__item--ode),
  .slick-slide:has(img.logo-banner__item--th)
   {
    transform: translateX(-1rem);
  }


  @media screen and (min-width: $tab) {

    .slick-slide:has(img.logo-banner__item--good) {
      transform: translateX(-.5rem);
    }

    .slick-slide:has(img.logo-banner__item--sh),
    .slick-slide:has(img.logo-banner__item--gb),
    .slick-slide:has(img.logo-banner__item--dun) {
      transform: translateX(-1rem);
    }

    .slick-slide:has(img.logo-banner__item--lfc),
    .slick-slide:has(img.logo-banner__item--ss) {
      transform: translateX(-1.5rem);
    }

    .slick-slide:has(img.logo-banner__item--arc) {
      transform: translateX(-2rem);
    }

    .slick-slide:has(img.logo-banner__item--mu),
    .slick-slide:has(img.logo-banner__item--lfc) {
      transform: translateX(1rem);
    }

    .slick-slide:has(img.logo-banner__item--toc) {
      transform: translateX(0);
    }

    .slick-slide:has(img.logo-banner__item--lwr),
    .slick-slide:has(img.logo-banner__item--lee),
    .slick-slide:has(img.logo-banner__item--mt) {
      transform: translateX(1.5rem);
    }

    .slick-slide:has(img.logo-banner__item--jet) {
      transform: translateX(2rem);
    }

    .slick-slide:has(img.blank) {
      transform: translateX(-99.9rem);
    }

    &:not(.logo-banner--roi) {
      .slick-slide:has(img.logo-banner__item--th) {
        transform: translateX(-13rem);
      }

      .slick-slide:has(img.logo-banner__item--ved) {
        transform: translateX(-12rem);
      }

      .slick-slide:has(img.logo-banner__item--gb) {
        transform: translateX(-9rem);
      }

      .slick-slide:has(img.logo-banner__item--wa) {
        transform: translateX(-5rem);
      }

      .slick-slide:has(img.logo-banner__item--ww) {
        transform: translateX(-2rem);
      }
    }

  }
  
  @media screen and (min-width: $tabDesk) {
    padding: 1rem 11.5rem;
  }

  @media screen and (min-width: 1400px) {

    .slick-slide:has(img.blank) {
      transform: translateX(-99.9rem);
    }

    &--roi {
      .slick-slide:has(img.logo-banner__item--sky) {
        transform: translateX(-13.5rem);
      }

      .slick-slide:has(img.logo-banner__item--sh) {
        transform: translateX(-12rem);
      }

      .slick-slide:has(img.logo-banner__item--tp){
        transform: translateX(-9.75rem);
      }
    
      .slick-slide:has(img.logo-banner__item--toc) {
        transform: translateX(-6.75rem);
      }

      .slick-slide:has(img.logo-banner__item--th) {
        transform: translateX(-6rem);
      }

      .slick-slide:has(img.logo-banner__item--ved) {
        transform: translateX(-5rem);
      }

      .slick-slide:has(img.logo-banner__item--gb) {
        transform: translateX(-1.75rem);
      }

      .slick-slide:has(img.logo-banner__item--wa) {
        transform: translateX(0rem);
      }

      .slick-slide:has(img.logo-banner__item--ww) {
        transform: translateX(1.5rem);
      }
    }

    &:not(.logo-banner--roi){
      .slick-slide:has(img.logo-banner__item--th) {
        transform: translateX(-6rem);
      }
  
      .slick-slide:has(img.logo-banner__item--ved) {
        transform: translateX(-5rem);
      }
  
      .slick-slide:has(img.logo-banner__item--gb) {
        transform: translateX(-1.75rem);
      }
  
      .slick-slide:has(img.logo-banner__item--wa) {
        transform: translateX(0rem);
      }
  
      .slick-slide:has(img.logo-banner__item--ww) {
        transform: translateX(1.5rem);
      }
    }
  
  
    .slick-slide:has(img.logo-banner__item--ars) {
      transform: translateX(-1.5rem);
    }
  
    .slick-slide:has(img.logo-banner__item--av) {
      transform: translateX(-4rem);
    }
  
    .slick-slide:has(img.logo-banner__item--good) {
      transform: translateX(-3.5rem);
    }
  
    .slick-slide:has(img.logo-banner__item--cel) {
      transform: translateX(-6rem);
    }

    .slick-slide:has(img.logo-banner__item--arc){
      transform: translateX(-6.75rem);
    }

    .slick-slide:has(img.logo-banner__item--cwa) {
      transform: translateX(-7.75rem);
    }

    .slick-slide:has(img.logo-banner__item--dun),
    .slick-slide:has(img.logo-banner__item--che)  {
      transform: translateX(-7.5rem);
    }
  }

  @media screen and (min-width: 1400px) and (max-width: $deskWideLarge){
    .slick-slide:has(img.logo-banner__item--sea) {
      transform: translate(.75rem, .25rem);
    }

    .slick-slide:has(img.logo-banner__item--cwo) {
      transform: translateX(-5rem);
    }

    .slick-slide:has(img.logo-banner__item--lee) {
      transform: translateX(-0rem);
    }

    .slick-slide:has(img.logo-banner__item--ode),
    .slick-slide:has(img.logo-banner__item--eye),
    .slick-slide:has(img.logo-banner__item--lfc) {
      transform: translateX(2rem);
    }

    .slick-slide:has(img.logo-banner__item--lwr) {
      transform: translateX(2.5rem);
    }

    .slick-slide:has(img.logo-banner__item--mu) {
      transform: translateX(3.25rem);
    }

    .slick-slide:has(img.logo-banner__item--mt) {
      transform: translateX(4rem);
    }

  }

  @media screen and (min-width: $deskWideLarge) {

    .slick-slide:has(img.logo-banner__item--cwo) {
      transform: translateX(-5.75rem);
    }

    .slick-slide:has(img.logo-banner__item--ars) {
      transform: translateX(-2.5rem);
    }

    .slick-slide:has(img.logo-banner__item--good),
    .slick-slide:has(img.logo-banner__item--av) {
      transform: translateX(-5rem);
    }
  
    .slick-slide:has(img.logo-banner__item--cel) {
      transform: translateX(-7rem);
    }
  
    .slick-slide:has(img.logo-banner__item--cwa),
    .slick-slide:has(img.logo-banner__item--dun),
    .slick-slide:has(img.logo-banner__item--arc),
    .slick-slide:has(img.logo-banner__item--che)  {
      transform: translateX(-8rem);
    }
  
  }
}