@import '../../styles/variables.scss';

.tooltip {
  $this: &;

  &__cta {
    display: block;
    width: 2rem;
    height: 2rem;
    border: 0.1rem solid $color-white;
    background: $color-primary;
    color: $color-white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: $font-family-base-weight;
    cursor: pointer;
    text-transform: none;
    text-align: center;
    padding: 0;

    &::before {
      display: none;
    }

    &:hover {
      border-color: $color-white;
      color: $color-white;
      background: $color-primary;
    }

    &:focus {
      border-color: $color-white;
      color: $color-white;
      background: $color-primary;
      box-shadow: 0 0 0px 2px $color-primary, 0px 0px 0px 3px $color-accent;
    }
  }
}