@import '../../styles/variables.scss';

.header {
  $this: &;
  position: fixed;
  transition: transform .3s;
  padding: 1rem 2rem 2rem;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: transparent;

  &::before,
  &::after {
    content: "";
    display: block;
    width: 140%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
  }

  &::before {
    height: calc(100% + 3px);
    background-color: $color-accent;
  }
  &::after {
    height: 100%;
    background-color: $color-primary;
  }

  &--hide {
    transform: translateY(-120%);
  }

  &--navopen {
    #{$this}__overlay {
      pointer-events: all;
      opacity: 1;
    }
  }

  &__group {
    color: $color-accent;
    text-transform: uppercase;
    padding: 1rem 0 2rem!important;
    font-weight: 600;
  }

  &__logo {
    display: flex;
    z-index: 2;

    img {
      display: block;
      width: auto;
      height: 5.2rem;
      margin-left: -8%;
    }

    /*&:focus {
      img {
        outline: 2px solid $color-accent;
      }
    }*/
  }

  &__nav {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 5;
  }

  &__link {
    font-family: $font-family-base;
    font-weight: $font-family-medium-weight;
    font-size: 2.8rem;
    line-height: 3.4rem;
    letter-spacing: 0.03rem;
    text-transform: uppercase;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;

    &--md {
      font-size: 1.8rem;
      line-height: 3.1rem;
      letter-spacing: 0;
    }
  }

  &__menuToggle {
    display: block;
    position: relative;
    top: 3rem;
    right: 3rem;
    z-index: 103;
    -webkit-user-select: none;
    user-select: none;

    input {
      display: block;
      width: 4rem;
      height: 3.2rem;
      position: absolute;
      top: -.7rem;
      left: -.5rem;
      cursor: pointer;
      opacity: 0; /* hide this */
      z-index: 2; /* and place it over the hamburger */
      -webkit-touch-callout: none;
    }

    &__span {
      display: block;
      width: 3.2rem;
      height: .4rem;
      margin-bottom: .5rem;
      position: relative;
      background: #fff;
      border-radius: .3rem;
      z-index: 1;
      transform-origin: .4rem 0px;
      transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                  background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                  opacity 0.55s ease;


      &:first-child {
        transform-origin: 0% 0%;
      }
  
      &:nth-last-child(2) {
        transform-origin: 0% 100%;
      }
    }

    &__focus {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    input:hover ~ .header__menuToggle__span {
      background: $color-accent;
    }

    input:checked ~ .header__menuToggle__span {
      opacity: 1;
      transform: rotate(45deg) translate(-2px, -1px);
      background: #fff;
    }

    input:checked:focus ~ .header__menuToggle__span {
      background: $color-accent;
    }

    input:focus ~ .header__menuToggle__focus {
      box-shadow: 0 0 0px 3px $color-primary, 0px 0px 0px 5px $color-accent;
    }

    input:checked ~ .header__menuToggle__span:nth-last-child(3) {
      opacity: 0;
      transform: rotate(0deg) scale(0.2, 0.2);
    }

    input:checked ~ .header__menuToggle__span:nth-last-child(2) {
      transform: rotate(-45deg) translate(1px, -1px);
    }

    input:checked ~ .header__menu {
      transform: none;
    }
  }

  &__menu {
    position: fixed;
    text-align: center;
    padding: 9rem 5rem 11.5rem;
    padding-top: 16rem;
    width: 100%;
    height: 100vh;
    top: 0;
    right: 0;
    background: $color-primary;
    -webkit-font-smoothing: antialiased;
    transform-origin: 0% 0%;
    transform: translate(100%, 0);
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;

    .link:focus {
      color: $color-white;
      outline: 2px solid $color-accent;
    }

    &__nav {
      margin: 0;
      padding: 0;
      list-style-type: none;

      li {
        margin-bottom: 5rem;
      }

      &__divider {
        border-top: 0.02rem solid $color-accent;
        padding-top: 2rem;
      }
    }

    &__footer {
      text-align: center;

      .link {
        text-transform: uppercase;
        font-size: 1.8rem;
        line-height: 2.1rem;
        font-weight: $font-family-medium-weight;
        text-decoration: none;
      }
    }
  }

  &__overlay {
    transition: opacity .2s;
    pointer-events: none;
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.5);
    z-index: 101;
  }

  &__visit {
    position: absolute;
    left: 1rem;
    top: 4rem;
    transform: translate(0,-50%);
    color: $color-accent;
    font-weight: 600;
    text-decoration: none;
    width: 11rem;
    text-align: center;
    width: 10.5rem;
    font-size: 1.4rem;
    line-height: 1.5rem;

    &:hover {
      text-decoration: underline;
    }
  }

  @media screen and (min-width: $tab) {
    &__menu {
      text-align: left;
      padding: 9.5rem 4rem 11.5rem;
      width: 27rem;
    }

    &__link {
      font-size: 2.8rem;
      line-height: 3.1rem;
      letter-spacing: 0;

      &--md {
        font-size: 1.8rem;
        line-height: 3.1rem;
        letter-spacing: 0;
      }
    }
  }

  @media screen and (min-width: $deskWide) {
    padding: 2.5rem 5rem 3rem;

    &::before,
    &::after {
      width: 130%;
      border-bottom-left-radius: 50% 60%;
      border-bottom-right-radius: 50% 60%;
    }

    &__logo {
      img {
        height: 8.2rem;
        margin-left: 0;
      }
    }

    &__menuToggle {
      top: 4rem;
      right: 5rem;
    }

    &__visit {
      top: 5.75rem;
      color: $color-primary;
      left: 2rem;
  
      &:hover {
        color: $color-primary;
      }
    }
    
  }

  @media screen and (min-width: $deskWide) {
    &::before,
    &::after {
      content: "";
      width: 115%;
      border-bottom-left-radius: 50% 80%;
      border-bottom-right-radius: 50% 80%;
    }

    &::before {
      bottom: -0.4rem;
      height: calc(100% + 4px);
    }

    &__menu {
      padding: 9.5rem 4rem 6rem;
    }
  }

}
