@import '../../styles/variables.scss';

.policies {
  text-align: left;

  + .policies {
    margin-top: 3rem;

    @media screen and (min-width: $tab) {
      margin-top: 5rem;
    }
  }

  &__heading {
    background-color: $color-secondary;
    color: $color-accent;
    text-transform: uppercase;
  }

  .table_wrapper {
    overflow-x: scroll;
  }

  table {
    width: 100%;
    margin: 3rem auto;
    border: 0.1rem solid $color-white;
    border-collapse: collapse;

    tr th {
      text-transform: uppercase;
      font-size: 1.6rem;
      word-break: initial;
    }

    &:first-of-type tr {
      border-bottom: 1px solid $color-primary;
    }
  }

  th {
    background-color: $color-white;
    color: $color-primary;
    padding: 1rem;
  }

  td {
    padding: 0.5rem;
    border: 1px solid $color-white;
    vertical-align: top;
    word-break: initial;
    //width: 50%;
  }

  .removeBullets {
    list-style-type: none;
  }


  &__content {
    margin-bottom: 4rem;
  }

  .type-h1 {
    text-align: center;
    margin-bottom: 2.7rem;

    span {
      text-transform: lowercase;
    }

    @media screen and (min-width: $tab) {
      margin-top: 1rem;
      margin-bottom: 1.4rem;
    }
  }

  .type-h2 {
    margin: 2rem 0 1rem;
  }

  p,
  a,
  li {
    word-break: break-word;
  }

  h2 {
    font-size: 2rem;
    margin-top: 3rem;
  }

  h3 {
    font-size: 1.6rem;
    margin: 0 0 1rem;
  }

  p {
    + p {
      margin-top: 2rem;
    }

    &.p_heading {
      + p {
        margin-top: 1rem;
        padding-left: 2rem;
      }
    }

    &.p_indent {
      padding-left: 2rem;
      margin-top: 1rem;
    }

    &.p_mt0 {
      margin-top: 0;
    }
  }

  p + ul,
  p + ol {
    margin-top: 1.5rem;
  }

  ul+h3,
  p+h3 {
    margin-top: 2rem;
  }

  ul,
  ol {
    margin: 0;
    padding: 0rem 1rem 1rem 1rem;

    + p {
      margin-top: 2rem;
    }

    &.list-none {
      list-style: none;
      padding-left: 2rem;

      @media screen and (min-width: $tab) {
        padding-left: 5rem;
      }
    }
  }

  li {
    margin-bottom: 0.7rem;
  }

  ol>li {
    margin-bottom: 2rem;

    @media screen and (min-width: $tab) {
      margin-bottom: 3rem;
    }
  }

  ol li>ol {
    margin-left: 2rem;

    >li {
      margin-bottom: 1rem;

      @media screen and (min-width: $tab) {
        margin-bottom: 1rem;
      }
    }
  }

  ol li>ol li>ol {
    margin-left: 2rem;
  }

  ol li>ol li>ol li>ol {
    margin-left: 2rem;
  }

  .reduced-list {
    li {
      margin-bottom: 1rem;
    }
  }

  //Add margin to cookie policy bullet points
  .cookie-list-margin {
    margin-left: 2rem;
  }

  &--alphalist {
    list-style-type: lower-alpha;
  }
}

#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy #cookie-policy-title,
#cookie-policy-description,
.ot-sdk-cookie-policy-group,
.ot-sdk-cookie-policy-group-desc,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy span,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy a,
.ot-host-td,
.ot-cookies-td-content,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy td::before {
  color: $color-white !important;
  background: transparent !important;
}

button#ot-sdk-btn {
  padding: 2rem 0 !important;
  display: inline-block;
  color: $color-white !important;
}

#onetrust-banner-sdk .ot-sdk-container {
  width: 90% !important;
  padding: 0 !important;

  @media screen and (min-width: $tab) {
    width: 100% !important;
  }
}

#onetrust-pc-sdk .ot-sdk-container,
#ot-sdk-cookie-policy .ot-sdk-container {
  margin: 0 !important;
  padding: 0 !important;
}

#onetrust-banner-sdk #onetrust-button-group {
  margin-right: auto !important;
}


#ot-sdk-btn.ot-sdk-show-settings,
#ot-sdk-btn.optanon-show-settings {
  position: relative;
  transition: all .3s !important;
  display: inline-block;
  width: 100%;
  color: $color-secondary !important;
  font-family: $font-family-bold;
  font-weight: $font-family-semibold-weight;
  font-size: 1.8rem !important;
  line-height: 1.8rem !important;
  letter-spacing: 0.054rem !important;
  text-transform: uppercase;
  border-radius: $button-border-radius;
  background: $button-background;
  background: $button-background-gradient;
  border: $button-border !important;
  padding: $button-padding !important;
  text-decoration: none;
  text-align: center;
  cursor: pointer !important;
  overflow: hidden;
  margin-top: 2rem;

  &:hover {
    outline: none;
    color: $color-white !important;
    background: $button-background-gradient-hover !important;
    border-color: $button-background-gradient-hover !important;
    box-shadow: none;
  }

  &:focus,
  &:active {
    outline: none;
    color: $color-secondary;
    box-shadow: 0 0 0px 3px $color-primary, 0px 0px 0px 5px $color-white;

    &:before {
      opacity: 1;
    }
  }


  @media screen and (min-width: $mobWide) {
    max-width: 36.4rem;
    letter-spacing: 0.09rem;
  }

}

#ot-sdk-cookie-policy {
  margin-top: 2rem;
}

.ot-sdk-cookie-policy-title {
  font-size: 2rem !important;
}

.ot-sdk-cookie-policy-group {
  font-size: 1.8rem !important;
  font-weight: $font-family-medium-weight !important;
}

#cookie-policy-description,
.ot-sdk-cookie-policy-group-desc,
.ot-cookies-td-content,
.ot-cookies-td-content a,
.ot-cookies-type-td-content,
.ot-table-header,
.ot-host-td,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy td::before,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy td {
  font-size: 1.6rem !important;
  line-height: 2.4rem !important;
}

#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy table th {
  color: $color-primary !important;
  font-weight: $font-family-medium-weight;
}