@import '../../styles/variables.scss';

.prize-block {
  overflow: hidden;
  background: transparent;
  position: relative;
  overflow: visible;
  border: .4rem solid $color-accent;
  border-radius: 1.5rem;
  padding: 2rem 0 4rem;
  $this: &;

  &__title {
    margin: 0;
    text-align: center;
    display: block;
    margin-top: 2.5rem;
    margin-bottom: 2rem;
    padding: 0 2rem;
    font-size: 2.4rem;
    line-height: 2.4rem;
    letter-spacing: 0;
    text-transform: uppercase;
    font-weight: $font-family-semibold-weight;
    color: $color-accent;

    span {
      text-transform: none;
    }
  }

  &__gradient {
    position: absolute;
    bottom: 0;
    height: 40%;
    width: 100%;
  
    &::before,
    &::after {
      content: "";
      display: block;
      height: 100%;
      position: absolute;
    }

    &::before {
      background: radial-gradient(ellipse at center, rgb(59, 0, 115) 0%,rgb(59, 0, 115) 41%,rgba(59,0,115,0) 74%,rgba(59,0,115,0) 100%);      background-repeat: none;
      height: 100%;
      width: 100%;
    }

    &::after {
      background: linear-gradient(to bottom, rgba(59, 0, 115, 0) 0%, rgba(59, 0, 115, 0.8) 15%, #3b0073 19%, #3b0073 20%, #3b0073 50%, #3b0073 80%, rgba(59, 0, 115, 0.8) 85%, rgba(59, 0, 115, 0) 100%);      
      background-size: cover;
      background-position: center;
      opacity: 0;
      width: 89%;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 59%;
    }
  }

  &__info {
    position: relative;
    padding: 0 2rem;

    &-copy {
      font-size: 1.6rem;
      line-height: 2.4rem;
      color: $color-white;
      max-width: 36rem;
      margin: 0 auto;

      img {
        display: block;
        box-sizing: border-box;
        max-width: 100%;
        margin: 0 auto;
      }

      span {
        text-transform: uppercase;
      }
    }
  }

  &__image {
    position: relative;

    img {
      display: block;
      position: relative;
      width: auto;
      height: auto;
      object-fit: contain;
      margin: 0 auto;
      z-index: 1;
      width: 100%;
    }
  }


  &:last-of-type {
    &::before {
      display: none;
    }

    &::after {
      display: none;
    }
  }

  + .prize-block {
    margin-top: 3rem;
  }

  &__toggle-content {
    font-weight: $font-family-semibold-weight;
    padding: 0 2rem;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    li + li, p + p {
      margin-top: 1rem;
    }
  }


  @media screen and (min-width: $tab) {
    padding: 5rem 3rem;

    &__container {
      display: flex;
      align-items: center;
    }

    &__title {
      padding: 0;
      margin: 0 0 1.8rem;
      font-size: 3.4rem;
      line-height: 3.4rem;
      letter-spacing: 0.03rem;
    }

    &__image {
      max-width: 50%;
    }

    &__info {
      max-width: 50%;
      padding: 0;
      padding-left: 2rem;

      &-wrap {
        text-align: left;
        padding: 0rem;
      }

      &-copy {     

        #{$this}__title  {
          max-width: 50rem;
          margin: 0 auto;
        }

        img {
          margin: 2rem auto 0rem;
        }

        + .button--text-link {
          margin-top: 1rem;
        }
      }
    }

    + .prize-block {
      margin-top: 4rem;
    }

    
    &--reverse {
      .prize-block__container {
        flex-direction: row-reverse;
      }

      .prize-block__info {
        padding-left: 0;
        padding-right: 2rem;
      }
    }
    
  }

  @media screen and (min-width: $tabDesk) {
    padding: 5rem;

    &__info {
      padding: 0 4rem 0 8rem;
    }

    &__image {
      img {
        width: auto;
      }
    }

    &--reverse {
      .prize-block {

        &__info {
          padding: 0 8rem 0 4rem;

          &-copy {
            p {
              padding: 0 1.2rem;
            } 
          }
        }
      }
    }


    }
    

}