@import '../../styles/variables.scss';

.form {
  box-sizing: border-box;
  
  &__fieldset {
    padding: 0;
    border: none;
    margin: 2.7rem 0;

    &__title {
      text-align: left;
      font-family: $font-family-base;
      font-weight: $font-family-semibold-weight;
      text-transform: uppercase;
      color: $color-accent;
      font-size: 1.8rem;
      line-height: 2.2rem;
      margin-bottom: 0.8rem;
    }

    &--hidden {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border-width: 0;
    }

    &--highlight {
      border-radius: 1rem;
      border: 0.3rem solid $color-accent;
      padding: 1rem 2.5rem 1.5rem;
      margin: 3rem 0rem 2.5rem 0rem;
    }

    &--captcha {
      margin: 3.5rem 0 3rem;
    }

    &--indent {
      padding-left: 4.1rem;
    }
  }

  &__submit {
    width: 100%;
    max-width: 36.4rem;

    &--hidden {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border-width: 0;

      &::before {
        display: none;
      }
    }
  }

  &__date-entry {
    padding-left: 6rem;
  }

  &__privacy {
    text-align: left;
    margin: 0;
    margin-top: 4rem;
    font-size: 1.4rem;
    line-height: 1.7rem;
    
    .title {
        font-weight: $font-family-medium-weight;
        margin-bottom: 1rem;
    }

    a {
      font-weight: $font-family-medium-weight;
    }

    &--reduced {
      font-size: 1.2rem;
    }
  }

  &__intro {
    margin-bottom: 1rem;

    p + p {
      margin-top: 1.5rem;
    }

    @media screen and (min-width: $tab) {
      p + p {
        margin-top: 2rem;
      }
    }
  }


  &--slim {
    max-width: 42.6rem;
    display: block;
    margin: 4rem auto 0rem;
  }


  @media screen and (min-width: $tab) {

    &--slim {
      margin-top: 4.6rem;
    }

    &__fieldset {
      &--highlight {
        margin: 3rem 0rem;
      }

      &--captcha {
        margin: 4rem 0 3.5rem;
      }
    }
  }
}

.g-recaptcha-v2 
{
  margin: 1 auto 0;
  display: inline-block;
}
