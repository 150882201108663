@import '../../styles/variables.scss';

.actionbar {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 9rem;
  padding: 0 2rem;
  pointer-events: none;
  position: fixed;
  bottom: 0;
  top: auto;
  width: 100%;
  z-index: 11;
  text-align: center;
  transform: translateY(100%);
  transition: transform .3s, opacity .3s;
  opacity: 0;
  pointer-events: none;
  box-sizing: border-box;
  padding: 0 2.0rem 3.8rem;
  //background: transparent linear-gradient(180deg, #3B007300 0%, #3B0073BF 100%) 0% 0% no-repeat padding-box;

 /* &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: transparent linear-gradient(180deg, #3B007300 0%, #3B0073BF 100%) 0% 0% no-repeat padding-box;
  }*/

  &--show {
    position: sticky;
    transform: translateY(0);
    opacity: 1;
    pointer-events: all;
    margin-top: -9rem;
  }

  * {
    pointer-events: all;
  }

}


.layout--shadow {
  position: relative;
  .layout-general--full-texture {
    margin-top: -14rem;
  }
  &:before {
    content: '';
    display: block;
    position: sticky;
    left: 0;
    bottom: auto;
    top: calc(100% - 140px);
    width: 100%;
    height: 14rem;
    background: transparent linear-gradient(180deg, #3B007300 0%, #3B0073BF 100%) 0% 0% no-repeat padding-box;
  }
}