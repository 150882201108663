@import '../../styles/variables.scss';

.claim {
  .type-h1 {
    font-size: 3rem;
    word-break: break-word;
  }

  .type-h2 {
    max-width: 35rem;
    margin-left: auto;
    margin-right: auto;

    &.type-h2--max {
      max-width: 54rem;
    }
  }

  .captcha-container {

    + .button--toload {
      margin-top: 0;
    }
  }

  &__image {
    width: auto;
    max-width: 100%;
    padding: 0 0 3rem;
  }

  .form {
    margin-top: 0;
    @media screen and (min-width: $tab) {
      margin-top: 0;
    }
  }

  .spinner
  {
    margin-top: 1rem;
    height: 40px;
  }
 
 /* .g-recaptcha-v2 
  {
    margin: 20px auto;
    display: inline-block;
  }*/

  @media screen and (min-width: $tab) {
    .type-h1 {
      font-size: 5.2rem;
    }

    .form__intro {
      max-width: 39rem;
      margin: 0 auto 3.2rem;
    }
  }
}

