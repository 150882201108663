@import '../../styles/variables.scss';

.prize-accordions {
    
    width: 100%;
    max-width: 109.7rem;

    .accordion--prize + .accordion--prize {
        margin-top: 1.6rem;
    }

    @media screen and (min-width: $tab) {
        width: 100%;
    

        .accordion--prize + .accordion--prize{
            margin-top: 3.5rem;
        }
    }
}