@import '../../styles/variables.scss';

.modal {
  width: 100%;
  padding: 3rem 4rem 6rem;
  margin: 5rem auto;
  //transition: all .6s;
  text-align: center;
  transform: scale(.9);
  opacity: 0;
  box-sizing: border-box;

  &--show {
    transform: scale(1);
    opacity: 1;
    &:focus-within, &:focus
    {
      outline: 0;
      border: 0;
    }
  }

  &--loader {
    display: flex;
    justify-content: center
  }

  &--panel {
    background: $color-primary;
    border: 0;
    padding: 1.8rem 1.8rem 5.9rem;
    border-radius: 1rem;

    .validation-message {
      text-align: center;
    }

    &--error {
      padding: 3.4rem 2.6rem;

      .modal{

        &__icon {
          margin-bottom: 2.4rem;
        }

        &__title {
          padding: 0 2rem;
          margin-bottom: 2rem;
        }
      }

      button + a,
      a + a {
        margin-top: 2rem;
      }
    }

    @media screen and (min-width: $tab) {
      padding: 2.9rem 5.1rem 6rem;

      &--error {
        padding: 3rem 11.8rem 5.6rem;

        .modal{

          &__icon {
            margin-bottom: 3.4rem;
          }
  
          &__title {
            padding: 0;
            margin-bottom: 1.6rem;
          }
  
          &__intro {
            font-size: 1.6rem;
            line-height: 2.4rem; 
            letter-spacing: 0;
          }
        }
      }
    }

    &--bordered {
      border: .3rem solid $color-white;
    }

  }

  &--extra {
    padding-top: 4.9rem;
    padding-bottom: 4.9rem;
    box-shadow: 0 0 0px 3px $color-white;
    margin: 0 auto;
    border-radius: .8rem;

    .modal__title {
      margin-top: 0;
    }

    &__intro {
      font-size: 1.8rem;
      line-height: 2.2rem;
      font-weight: $font-family-medium-weight;
  
      &:focus,
      &:focus-visible { 
        outline: none; 
      }

      a {
        display: inline-block;
        word-break: break-word;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
      }
    }    
  }

  &--prize {
    padding-top: 6.3rem;
  }

  &--country {
    padding: 3.1rem 2rem 4.9rem;
    box-shadow: 0 0 0px 3px $color-white;
    margin: 0 auto;
    border-radius: .8rem;

    .form {
      .country-select {
        &__intro {
          padding: 0 4rem;
        }
      }
    }
  }

  &__icon {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3rem;

    &--rounded {
      border-radius: 50%;
      margin-bottom: 3rem;
      width: 16.5rem;
      height: 16.5rem;
      object-fit: cover;

      @media screen and (min-width: $tab) {
        width: 23.8rem;
        height: 23.8rem;
      }
    }

    &--bordered {
      border: .3rem solid $color-white;
    }
  }

  &__title {
    text-transform: uppercase;
    font-family: $font-family-bold;
    font-weight: $font-family-medium-weight;
    font-size: 2rem;
    line-height: 2rem;
    letter-spacing: 0.06rem;
    color: $color-accent;
    margin-bottom: 1.2rem;
  }

  p {
    background: none;
    border: none;
    padding: 0;
    width: 100%;
  }

  a ~ a, button ~ a {
    margin-top: 2rem;
  }

  .button , .link--lozenge {
    display: block;
    margin: 2.6rem auto 0;

    + .link {
      display: inline-block;
    }
  }


  .button--camera {
    margin: 3rem auto 0;
  }

  &__close {
    text-indent: -200px;
    overflow: hidden;
    position: absolute;
    top: -1rem;
    right: -1rem;
    border-radius: 50%;
    background: $color-white;
    border: none;
    width: 5.3rem;
    height: 5.3rem;
    cursor: pointer;

    &:after, &:before {
      content: '';
      position: absolute;
      width: 0.5rem;
      height: 3rem;
      left: 50%;
      top: 50%;
      border-radius: 1rem;
      background: $color-secondary;
    }

    &:before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &:after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    &:focus,
    &:focus-visible {
      outline: none;
      box-shadow: 0 0 0px 3px $color-primary, 0px 0px 0px 5px $color-accent;
    }
  }

  &__footer {
    margin-top: 2rem;
  }

  &__disclaimer {
    margin: 0 auto;
    margin-top: 2rem;
    max-width: 36.4rem;

    p {
      font-size: 1.6rem;
      line-height: 1.8rem;
    }
  }

  &--tooltip {
    max-width: 30rem;
    padding: 4.5rem 5rem;

    .modal__title {
      margin-top: 0;
    }

    p {
      font-size: 1.6rem;
      line-height: 2rem;
    }
  }


  &--details-info {
    background: #fff;
    padding: 2.9rem 1.8rem 3.8rem;

    .modal__close {
      background: $color-primary;

      &:after, &:before  {
        background: #fff;
      }
    }

    .card-details {
      display: flex;
      flex-direction: column;
      
      p,
      .type-h3
       {
        color: $color-primary;
        margin-bottom: 2.2rem;
      }

      p {
        margin-bottom: 2.6rem;
      }

      &__image {
        width: auto;
        height: auto;
        max-width: 14.2rem;
        max-height: 12rem;
        object-fit: contain;
        margin: 0 auto;
        margin-bottom: 2.5rem;

        &--icon {
          width: auto;
          max-width: 24.1rem;
        }
      }
    }
  }



  @media screen and (min-width: $tab) {
    max-width: 60rem;

    p{
      font-size: 1.8rem;
      line-height: 2.2rem;
    }

    &__close {
      transform: translate(50%, -50%);
      top: 0rem;
      right: 0rem;
    }

    &__icon {
      max-width: 23.8rem;
    }

    &__title {
      font-size: 2.4rem;
      line-height: 2.4rem;
      letter-spacing: 0.074rem;
      margin-bottom: 1.5rem;
    }

    &--panel--slim {
      padding: 3rem 11.8rem 2.5rem;
    }

    &--extra {
      padding: 5.7rem;
    }

    &--country {
      padding: 5.7rem;

      .form {
        .country-select {
          &__intro {
            padding: 0;
          }
        }
      }
    }

    &--details-info {
      padding: 3.4rem 9.5rem 2rem;

      .card-details {

        p {
          font-size: 1.6rem;
          line-height: 2.4rem;
        }
        
        .type-h3 {
          padding: 0 4rem;
        }
      }
    }

    .button, .link--lozenge {
      margin: 3rem auto 0;
    }

    &--panel--wide {
      width: 102.5rem;
      max-width: 102.5rem;
    }

    &--prize {
      max-width: 60rem;
    }
  
  }

  @media screen and (min-width: $tabDesk) {
    &--country {
      padding: 5.8rem 12rem;
    }

    &--prize {
      max-width: 80rem;
    }
  }

  @media screen and (min-width: $deskWide) {
    margin: 10rem auto;

    &--prize {
      max-width: 120rem;
    }
    
  }



  .button {
    width: 100%;
    

    @media screen and (min-width: $mobWide) {
      min-width: 26.6rem;
    }
    
    @media screen and (min-width: $tab) {
      width: 36.4rem;
    }
  }
}