@import '../../styles/variables.scss';

.prize-card {
    $this: &;
    position: relative;
    display: block;
    height: 100%;
    color: $color-secondary;
    background-image: url(../../assets/images/campaign/prize-card-bg--roi.png);
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 1.5rem;
    overflow: hidden;
    padding: 3rem 2rem 5rem;

    &__image {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 20.2rem;
        
        img {
            display: block;
            width: auto;
            height: auto;
            object-fit: contain;
        }

        &--top {
            align-items: flex-start;
        }
    }

    &__title {
        font-size: 3rem;
        line-height: 2.4rem;
        letter-spacing: 0.03rem;
        font-weight: $font-family-medium-weight;
        text-transform: uppercase;
        margin: 0 0 1rem;
    }

    &__info {

        p {
            font-size: 1.4rem;
            line-height: 2.2rem;
            letter-spacing: 0.03rem;
            font-weight: $font-family-medium-weight;

            @media screen and (min-width: $tab) {
                font-size: 1.6rem;
                line-height: 2.4rem;
                letter-spacing: 0.048rem;
            }
        }

        &__cta {
            position: absolute;
            left: 50%;
            bottom: 1rem;
            transform: translateX(-50%);
            font-size: 1.4rem;
            line-height: 2.2rem;
            letter-spacing: 0.042rem;
            font-weight: $font-family-medium-weight;
            color: $color-primary;

            &:focus {
                opacity: 1;
                color: $color-primary;
            }

            @media screen and (min-width: $tab) {
                font-size: 1.6rem;
                line-height: 2.4rem;
                letter-spacing: 0.048rem;
            }

            @media screen and (min-width: $deskWide) {
                opacity: 0;
            }
        }
    }



    &__overlay {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        visibility: hidden;
        padding: 1.7rem 2.5rem 2rem;
        background-color: $color-white;
        transition: all .3s ease-in-out;

        img {
            display: block;
            margin: 0 auto;
            width: auto;
            height: auto;
            max-height: 5rem;
            max-width: 100%;
        }

        p,
        #{$this}__overlay__cta {
            font-weight: $font-family-medium-weight;
            text-transform: uppercase;
            font-size: 1.6rem;
            line-height: 1.6rem;
            color: $color-secondary;
        }

        &__close {
            text-decoration: none;
            color: $color-primary;
            position: absolute;
            top: 0;
            right: 0;
            padding: 1rem 2rem;
            opacity: 0;
            visibility: hidden;

            &:focus {
                color: $color-primary;   
            }
        }

        &__cta {
            display: inline-block;
            margin: 1rem 0;
            flex-shrink: 1;
            text-decoration: underline;

            &:hover {
                color: $color-accent !important;
            }
        }

        &__info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
        }

        &__item {
            + #{$this}__overlay__item {
                margin-top: 2rem;
            }

            p {
                margin-bottom: 0;
                padding: 0 1.5rem;

                @media screen and (min-width: $mob) {
                    padding: 0 3rem;
                }

                @media screen and (min-width: $tab) {
                    padding: 0 1.5rem;
                }

            }

            img {
                margin-bottom: .5rem;

                &.medium {
                    margin-bottom: 1.5rem;
                    max-height: 6.5rem;
                }

                &.large {
                    margin-bottom: 4rem;
                }
                
            }


        }
        
    }

    &--gb {
        background-image: url(../../assets/images/campaign/prize-card-bg.png);
    }

    @media screen and (min-width: $deskWide) {
        &:focus-within {
            box-shadow: 0 0 0px 3px $color-primary, 0px 0px 0px 5px $color-accent;
        }

        &:hover {
            #{$this}__overlay {
                visibility: visible;
                opacity: 1;
            }
        }
    }

    &.focus-active {
        #{$this}__overlay {
            visibility: visible;
            opacity: 1;
        }

        #{$this}__overlay__close {
            opacity: 1;
            visibility: visible;
        }
    }
}
