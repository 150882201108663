@import '../../styles/variables.scss';

.code-entry {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__copy {
    p {
      font-size: 1.6rem;
      line-height: 2.4rem;
      padding: 0 4rem;

      + p {
        margin-top: 0.5rem;
      }
    }
  }

  &__inputs {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 2.5rem;

    @media screen and (min-width: $tab) {
      margin-top: 3.5rem;
    }
  }

  &__codes {
    display: flex;
    justify-content: center;
    flex-direction: column;

    .form__fieldset {
      width: 100%;
      flex-shrink: 1;
      margin: 0;
      padding: 0;

      + .form__fieldset {
        margin-top: 1.2rem;
      }

      .textfield__label {
        font-size: 1.6rem;
        line-height: 1.8rem;
      }

    }

    .textfield + .textfield {
      margin-top: 2rem;
    }

    @media screen and (min-width: $tab) {
      .form__fieldset {
        &:first-child {
          margin-right: 3rem;
        }
      }
    }
  }

  .form__submit {
    margin-top: 4.1rem;
  }

  .type-h3 {
    margin-bottom: 2.2rem;
    letter-spacing: 0;
  }

  &__retailer {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  @media screen and (min-width: $tab) {
    &__inputs {
      width: 100%;
    }

    &__codes {
      flex-direction: row;
      justify-content: space-between;

      .form__fieldset + .form__fieldset {
        margin-top: 0;
      }

      .textfield + .textfield {
        margin-top: 0;
        margin-left: 2rem;
      }
    }

    .form__submit {
      margin-top: 3.9rem;
    }
  }
}

/*.overflow-hidden-mediumdown {
  overflow: hidden;
}*/