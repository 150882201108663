@import '../../styles/variables.scss';

.fade-in {
  opacity: 0;
  visibility: hidden;
  transform: scale(0.8);
  transition: visibility linear 1200ms, opacity 1200ms , transform 1200ms;

  &.fade-in--visible {
    opacity: 1;
    visibility: visible;
    transform: scale(1.0);
    transition: visibility linear 1200ms, opacity 1200ms , transform 1200ms;
  }
}
