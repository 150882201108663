@import '../../styles/variables.scss';

.carousel {
  $this: &;

  &__wrapper {
    padding: 0 2rem;

    @media screen and (min-width: $tab) {
      padding: 0 3rem;
    }
  }
}


.swiper-container {
  width: 100%;
  padding-bottom: 3.5rem;

  @media screen and (min-width: $tab) {
    padding-bottom: 3.5rem;
  }
}

.swiper-slide {
  height: initial;
}


.swiper-pagination {
  width: 100%;
}

.swiper-pagination-bullet {
  background: transparent;
  opacity: 1;
  width: 1rem;
  height: 1rem;
  margin: 0 8px!important;
  border: 0.1rem solid $color-white;

  @media screen and (min-width: $tab) {
    width: 1.5rem;
    height: 1.5rem;
  }

  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0px 3px $color-primary, 0px 0px 0px 5px $color-accent;
  }
  
  &.swiper-pagination-bullet-active {
    box-shadow: 0 0 0px 3px $color-primary, 0px 0px 0px 5px $color-white;
    background-color: $color-white;
    border-color: transparent;

    &:focus-visible {
      box-shadow: 0 0 0px 3px $color-primary, 0px 0px 0px 5px $color-accent;
    }
  }
}


.swiper-button-prev, .swiper-button-next {
  display: block;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="13" height="21" viewBox="0 0 13 21"><path id="Path_14186" data-name="Path 14186" d="M307.151,456.829a2.406,2.406,0,0,0-3.416,0l-6.389,6.425-6.364-6.451a2.406,2.406,0,0,0-3.416,0,2.439,2.439,0,0,0,0,3.435l8.1,8.143a2.406,2.406,0,0,0,3.416,0l8.071-8.116A2.437,2.437,0,0,0,307.151,456.829Z" transform="translate(-456.091 307.859) rotate(-90)" fill="%234B0983"/></svg>');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1.1rem 1.9rem;

  
  border-radius: 50%;
  width: 4.8rem;
  height: 4.8rem;
  border: 0;
  background-color: $color-white;

  padding: 0.5rem;
  //display: none;
  position: absolute;
  text-align: center;
  outline: none;
  z-index: 5;

 
  &:hover {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="13" height="21" viewBox="0 0 13 21"><path id="Path_14186" data-name="Path 14186" d="M307.151,456.829a2.406,2.406,0,0,0-3.416,0l-6.389,6.425-6.364-6.451a2.406,2.406,0,0,0-3.416,0,2.439,2.439,0,0,0,0,3.435l8.1,8.143a2.406,2.406,0,0,0,3.416,0l8.071-8.116A2.437,2.437,0,0,0,307.151,456.829Z" transform="translate(-456.091 307.859) rotate(-90)" fill="%23EBC373"/></svg>');
  }

  &:focus {
    border-color: $color-accent;
    box-shadow: 0 0 0px 3px $color-primary, 0px 0px 0px 5px $color-accent;
    background-color: $color-white;
  }
  
  &:after {
    display: none;
  }

  &:focus:after, &:focus:before {
    //background: $color-focus;
  }

  @media screen and (min-width: $tabDesk) {
    height: 4.2rem;
    width: 4.2rem;
  }
}

.swiper-button-prev {
  transform: rotate(-180deg);
}
