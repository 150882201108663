@import '../../styles/variables.scss';

.step {
  position: relative;
  width: 24rem;
  box-sizing: border-box;

  & ~ & {
    margin-top: 4.5rem;
  }

  &__num {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 3px solid $color-secondary;
    background: $color-white;
    width: 5rem;
    height: 5rem;
    left: 0rem;
    top: 0rem;
  }

  &__title {
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: $font-family-medium-weight;
    margin: 1.5rem auto .5rem;
  }

  &__info {
    max-width: 23rem;
    margin: 0 auto;
    font-size: 1.4rem;
  }

  &__number {
    position: relative;
    color: $color-secondary;
    font-family: $font-gill-nova;
    font-weight: $font-family-bold-weight;
    font-size: 3.4rem;
    line-height: 3.8rem;
    z-index: 2;
    height: 100%;
  }

  &__image {
    display: block;
    width: 100%;
    border-radius: $step-border-radius;
    border: $step-border;
    max-width: 24rem;
    box-sizing: border-box;
  }

  @media screen and (min-width: $tab) {
    
    & ~ & {
      margin-top: 0;
    }
  }
}