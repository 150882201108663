@import "../../styles/variables.scss";

.custom-dropdown {
    $this: &;
    background-color: $color-white;
    height: 100%;
    color: $color-grey;
    border: $textfield-border;
    border-radius: $textfield-border-radius;

    &:focus {
        outline: none;
    }

    img {
        display: block;
        width: auto;
        height: 1.7rem;

        + span {
            margin-left: .5rem;
        }
    }
    
    &__selected {
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        color: $color-grey;
        font-weight: $font-family-book-weight;


        width: 100%;
        background-color: $color-white;
        -webkit-appearance: none;
        -moz-appearance: none;
        background-image: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="%233B0073" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"/></svg>');
        background-repeat: no-repeat;
        background-position-x: calc(100% - 0.5rem);
        background-position-y: 50%;
        border-radius:  $textfield-border-radius;
        padding: $textfield-padding;
        padding-right: 3rem;
        padding-left: 1rem;

        &:focus {
            outline: none;
           // background-color: rgba($color-grey, 0.1);
        }

        &::-webkit-details-marker {
            display: none;
          }
    }

    &__options {
        position: absolute;
        background-color: $color-white;
        width: 100%;
        
        border-bottom-right-radius: .8rem;
        border-bottom-left-radius: .8rem;
        overflow: hidden;


        &__item {
            input {
                &:focus {
                    
                    + label:not(.checkbox__label) {
                        background-color: rgba($color-grey, 0.3);
                    }
                }
            }

            label:not(.checkbox__label) {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: $textfield-padding;
                padding-right: 3rem;
                padding-left: 1rem;
                cursor: pointer;
            }

            &.isSelected label:not(.checkbox__label) {
                background-color: rgba($color-grey, 0.2);
            }

        }
    }

    &--multiple {
        position: relative;

        #{$this}__selected {
            color: $color-secondary;
            font-weight: $font-family-medium-weight;
        }

        #{$this}__options {
            max-height: 19.5rem;
            z-index: 1;
            overflow: auto;

            &__item {
                padding: 0.5rem 1rem 0;
            }

            @media screen and (min-width: $tab) {
                max-height: 26rem;
            }
        }
    }
}